import api from "./api";

const getOverview = async () => {
  const response = await api({
    method: "GET",
    url: "/statistics/overview",
  });

  return response;
};

const getRevenue = async (params) => {
  const response = await api({
    method: "GET",
    url: "/statistics/revenue",
    params,
  });

  return response;
};

const getUserRevenue = async (userId, params) => {
  const response = await api({
    method: "GET",
    url: `/statistics/${userId}/revenue`,
    params,
  });

  return response;
};

const getUserWorkTime = async (userId, params) => {
  const response = await api({
    method: "GET",
    url: `/statistics/${userId}/work-time`,
    params,
  });

  return response;
};

export { getOverview, getRevenue, getUserRevenue, getUserWorkTime };
