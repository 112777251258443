import React, { useEffect, useState } from "react";

import Table from "../../components/Table";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import debounce from "../../utils/debounce";
import { PAGINATION_LIMIT } from "../../constants";
import { formatMoney } from "../../utils/money";
import dayjs from "dayjs";

const ContributionList = ({
  search,
  date,
  userId,
  paging,
  onChangePage,
  loading,
  setLoading,
}) => {
  const [contributions, setContributions] = useState([]);

  const handleChangePage = (page) => onChangePage({ ...paging, page });

  const fetchContributions = async () => {
    setLoading(true);
    try {
      const [startDate, endDate] = date;
      const condition = {
        search,
        userId,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.contribution.getContributions(condition);
      setContributions(res.contributions);
      onChangePage({ ...paging, total: res?.total || 0 });
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "customerName",
      title: "Khách hàng",
      align: "left",
    },
    {
      field: "serviceName",
      title: "Dịch vụ",
      align: "left",
    },
    {
      title: "Số tiền (VNĐ)",
      align: "left",
      render: (row) => formatMoney(row.value),
    },
    {
      field: "userName",
      title: "Người chốt thẻ",
      align: "left",
    },
    {
      field: "creatorName",
      title: "Người tạo",
      align: "left",
    },
    {
      title: "Ngày tạo",
      align: "left",
      render: (row) => dayjs(row.createdAt).format("HH:mm DD-MM-YYYY"),
    },
  ];

  useEffect(() => {
    debounce(fetchContributions, 500)(search);
  }, [paging.page, search, date, userId]);

  return (
    <Table
      data={contributions}
      columns={columns}
      total={paging.total}
      page={paging.page}
      loading={loading}
      onChangePage={handleChangePage}
      showNumber={true}
    />
  );
};

export default ContributionList;
