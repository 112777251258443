import { Typography } from "@mui/material";

const Title = ({ title, bgColor, mb }) => {
  return (
    <Typography
      sx={{
        padding: "10px 15px",
        fontWeight: 500,
        textTransform: "uppercase",
        marginBottom: mb || "1rem",
        backgroundColor: bgColor,
      }}
    >
      {title}
    </Typography>
  );
};

export default Title;
