import api from "./api";

const login = async ({ phoneNumber, password }) => {
  const loginInfo = await api({
    method: "POST",
    url: "/login",
    data: { phoneNumber, password },
  });
  return loginInfo;
};

const getMe = async () => {
  const me = await api({
    method: "GET",
    url: "/me",
  });
  return me;
};

const logout = async () => {
  const logoutInfo = await api({
    method: "GET",
    url: "/logout",
  });
  return logoutInfo;
};

const changePassword = async (data) => {
  const response = await api({
    method: "PUT",
    url: "/change-password",
    data,
  });

  return response;
};

export { login, getMe, logout, changePassword };
