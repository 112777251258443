import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { formatMoney } from "../../utils/money";
import { TOOLTIP_BOX_SHADOW } from "../../styles/config";

const Overview = () => {
  const [overview, setOverview] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchOverview = async (displayLoading = false) => {
    if (displayLoading) setLoading(true);
    try {
      const res = await apis.statistic.getOverview();
      setOverview(res.overview);
    } catch (error) {
      handleCallApiError(error);
    }
    if (displayLoading) setLoading(false);
  };

  useEffect(() => {
    fetchOverview(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchOverview();
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box mb={3}>
      <Typography variant="h6" mb={2}>
        Tổng quan
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ boxShadow: TOOLTIP_BOX_SHADOW }}>
            <CardContent>
              {loading ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 2 }} />
                  <Skeleton variant="rectangular" sx={{ height: "28px" }} />
                </>
              ) : (
                <>
                  <Typography variant="body1" color="text.secondary" mb={2}>
                    Doanh thu trong ngày
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    textAlign="right"
                    mb={0}
                  >
                    {`${formatMoney(overview.revenueInDay)} VNĐ`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ boxShadow: TOOLTIP_BOX_SHADOW }}>
            <CardContent>
              {loading ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 2 }} />
                  <Skeleton variant="rectangular" sx={{ height: "28px" }} />
                </>
              ) : (
                <>
                  <Typography variant="body1" color="text.secondary" mb={2}>
                    Doanh thu trong tháng
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    textAlign="right"
                    mb={0}
                  >
                    {`${formatMoney(overview.realRevenueInMonth)} VNĐ`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ boxShadow: TOOLTIP_BOX_SHADOW }}>
            <CardContent>
              {loading ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 2 }} />
                  <Skeleton variant="rectangular" sx={{ height: "28px" }} />
                </>
              ) : (
                <>
                  <Typography variant="body1" color="text.secondary" mb={2}>
                    Doanh thu theo hóa đơn trong tháng
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    textAlign="right"
                    mb={0}
                  >
                    {`${formatMoney(overview.revenueInMonth)} VNĐ`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card sx={{ boxShadow: TOOLTIP_BOX_SHADOW }}>
            <CardContent>
              {loading ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 2 }} />
                  <Skeleton variant="rectangular" sx={{ height: "28px" }} />
                </>
              ) : (
                <>
                  <Typography variant="body1" color="text.secondary" mb={2}>
                    Tổng dư nợ tích lũy
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    textAlign="right"
                    mb={0}
                  >
                    {`${formatMoney(overview.debt)} VNĐ`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Overview;
