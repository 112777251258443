import React from "react";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Divider from "@mui/material/Divider";

import { StyledDialog } from "./index.style";

const Dialog = ({ title, subTitle, open, maxWidth, children, onClose }) => (
  <StyledDialog fullWidth maxWidth={maxWidth} open={open}>
    <DialogTitle classes={{ root: "dialog-title" }}>
      {title}
      <IconButton
        aria-label="close"
        onClick={onClose}
        color="secondary"
        className="close-button"
      >
        <Close color="secondary" />
      </IconButton>
      {subTitle && <Typography className="sub-title">{subTitle}</Typography>}
    </DialogTitle>
    <Divider />
    {children}
  </StyledDialog>
);

export default Dialog;
