import React, { useState } from "react";
import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";

import { INITIAL_PAGING } from "../../constants";
import ServiceList from "./ServiceList";
import ServiceDialog from "./ServiceDialog";

const INIT_SERVICE = {
  name: "",
  desc: "",
  numberOfSessions: 1,
  price: 0,
  discount: 0,
  duration: 60,
};

const Service = () => {
  const [search, setSearch] = useState("");
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [serviceDialog, setServiceDialog] = useState(false);
  const [service, setService] = useState({});
  const [reload, setReload] = useState(false);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleChangePaging = ({ page, total }) => {
    setPaging({ page: page ?? 1, total: total ?? 0 });
  };

  const handleOpenUserDialog = () => {
    setService(INIT_SERVICE);
    setServiceDialog(true);
  };

  const handleCloseUserDialog = () => {
    setServiceDialog(false);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              value={search}
              size="small"
              fullWidth
              placeholder={"Tìm kiếm theo tên dịch vụ"}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              minwidth="400px"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                color="success"
                sx={{
                  paddingTop: "7px",
                  paddingBottom: "6.5px",
                  marginRight: "5px",
                }}
                onClick={handleOpenUserDialog}
              >
                Thêm dịch vụ
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <ServiceList
          search={search}
          paging={paging}
          onChangePage={handleChangePaging}
          reload={reload}
        />
      </Box>
      <ServiceDialog
        open={serviceDialog}
        handleClose={handleCloseUserDialog}
        service={service}
        setService={setService}
        handleReload={handleReload}
        dialogType="create"
      />
    </>
  );
};

export default Service;
