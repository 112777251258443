import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";

const UpdateOrderItemDialog = ({ open, handleClose, orderItem, reload }) => {
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    handleClose();
    setNote("");
  };

  const handleUpdateOrderItem = async () => {
    setLoading(true);
    try {
      await apis.orderItem.updateOrderItem(orderItem.id, note);
      handleCloseDialog();
      reload();
      toast.success("Cập nhật đăng ký dịch vụ thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setNote(orderItem.note || "");
    }
  }, [open, orderItem.note]);

  return (
    <Dialog
      title="Thêm ghi chú"
      subTitle={orderItem.name}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Box mb={2}>
          <Typography fontWeight={500} mb={1}>
            Ghi chú:
          </Typography>
          <TextField
            value={note}
            size="small"
            fullWidth
            onChange={(event) => setNote(event.target.value)}
            multiline={true}
            rows={3}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>

        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleUpdateOrderItem}
        >
          Lưu thay đổi
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateOrderItemDialog;
