import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import ROUTE from "../../constants/route";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import tabs from "./config";
import { StyledUserDetail } from "./index.style";

const UserDetail = () => {
  const history = useHistory();
  const { userId } = useParams();

  const [user, setUser] = useState({});
  const [value, setValue] = useState("1");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleGoBack = () => history.push(ROUTE.USER);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchUser = async () => {
    setLoading(true);
    try {
      const res = await apis.user.getUser(userId);
      setUser(res.user);
    } catch (error) {
      handleCallApiError(error);
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  if (loading)
    return (
      <Box padding={2}>
        <CircularProgress />
      </Box>
    );

  if (error)
    return (
      <Box padding={2}>
        <Tooltip title="Quay lại">
          <IconButton onClick={handleGoBack} size="large">
            <ArrowBack fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Typography variant="body1" textAlign="center">
          Không tìm thấy nhân sự !
        </Typography>
      </Box>
    );

  return (
    <StyledUserDetail>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        mb={1}
      >
        <Tooltip title="Quay lại">
          <IconButton onClick={handleGoBack} size="large">
            <ArrowBack fontSize="inherit" />
          </IconButton>
        </Tooltip>

        <Typography variant="h5" marginBottom={1} fontSize="22px">
          {user.role?.name}: {user.name} - {user.phoneNumber}
        </Typography>
      </Stack>
      <TabContext value={value}>
        <Box className="nav-tabs">
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="lab API tabs example"
          >
            {tabs.map((tab) => (
              <Tab
                label={tab.name.toUpperCase()}
                value={tab.value}
                key={tab.value}
                className="tab selected-tab"
              />
            ))}
          </TabList>
        </Box>
        {tabs.map(({ component: Component, value }) => (
          <TabPanel key={value} value={value} className="tab-panel">
            <Component user={user} />
          </TabPanel>
        ))}
      </TabContext>
    </StyledUserDetail>
  );
};

export default UserDetail;
