export const PAYMENT_TYPE = {
  FULL_PAYMENT: "full_payment",
  PARTIAL_PAYMENT: "partial_payment",
};

export const PAYMENT_TYPE_LABEL = {
  [PAYMENT_TYPE.FULL_PAYMENT]: "Thanh toán toàn bộ",
  [PAYMENT_TYPE.PARTIAL_PAYMENT]: "Thanh toán một phần",
};

export const PAYMENT_TYPE_SHORT_LABEL = {
  [PAYMENT_TYPE.FULL_PAYMENT]: "Toàn bộ",
  [PAYMENT_TYPE.PARTIAL_PAYMENT]: "Một phần",
};

export const PAYMENT_METHOD = {
  TRANSFER: "transfer",
  CASH: "cash",
};

export const PAYMENT_METHOD_LABEL = {
  [PAYMENT_METHOD.CASH]: "Tiền mặt",
  [PAYMENT_METHOD.TRANSFER]: "Chuyển khoản",
};

export const INIT_ORDER_ITEM = {
  serviceId: null,
  numberOfSessions: 1,
  discount: 0,
  discountPercent: 0,
};

export const INIT_ORDER_ITEM_ERROR = {
  serviceId: "",
  numberOfSessions: "",
  discount: "",
  discountPercent: "",
};

export const INIT_ORDER = {
  // Order info
  orderItems: [INIT_ORDER_ITEM],
  paymentType: PAYMENT_TYPE.FULL_PAYMENT,
  note: "",

  // Transaction info
  amountOfMoney: 0,
  method: PAYMENT_METHOD.TRANSFER,
};

export const ORDER_STATUS = {
  DONE: "done",
  INCOMPLETE: "incomplete",
  CANCELLED: "cancelled",
};

export const ORDER_STATUS_LABEL = {
  [ORDER_STATUS.DONE]: "Đã xong",
  [ORDER_STATUS.INCOMPLETE]: "Chưa xong",
  [ORDER_STATUS.CANCELLED]: "Đã hủy",
};
