import api from "./api";

const createHistory = async (data) => {
  const response = await api({
    method: "POST",
    url: "/usage-histories",
    data,
  });

  return response;
};

const updateHistory = async (historyId, data) => {
  const response = await api({
    method: "PUT",
    url: `/usage-histories/${historyId}`,
    data,
  });

  return response;
};

const getHistories = async (params) => {
  const response = await api({
    method: "GET",
    url: `/usage-histories`,
    params,
  });

  return response;
};

const deleteHistory = async (historyId) => {
  const response = await api({
    method: "DELETE",
    url: `/usage-histories/${historyId}`,
  });

  return response;
};

export { createHistory, updateHistory, getHistories, deleteHistory };
