import React, { useState } from "react";
import dayjs from "dayjs";
import { Menu, MenuItem, TextField, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { StyledCustomDatePickerRange } from "./index.style";

const DatePickerRange = ({ value = [null, null], onChange }) => {
  const [anchorSelectDay, setAnchorSelectDay] = useState(null);

  const handleClickSelectDay = (e) => setAnchorSelectDay(e.currentTarget);
  const handleCloseSelectDay = () => setAnchorSelectDay(null);

  const handleChange = (label, newValue) => {
    if (!newValue || !newValue?.get("date") || !onChange) return;

    if (label === "startDate" && newValue !== value[0]) {
      if (newValue > value[1])
        onChange([newValue, dayjs(newValue).endOf("date")]);
      else onChange([newValue, value[1]]);
      return;
    }

    if (newValue !== value[1]) {
      if (newValue < value[0])
        onChange([newValue, dayjs(newValue).endOf("date")]);
      else onChange([value[0], newValue]);
    }
  };

  const optionsDatePicker = [
    {
      label: "Hôm nay",
      startDate: dayjs().startOf("date"),
      endDate: dayjs().endOf("date"),
    },
    {
      label: "Ngày mai",
      startDate: dayjs().add(1, "d").startOf("date"),
      endDate: dayjs().add(1, "d").endOf("date"),
    },
    {
      label: "Tuần này",
      startDate: dayjs().startOf("week"),
      endDate: dayjs().endOf("week"),
    },
    {
      label: "Tháng này",
      startDate: dayjs().startOf("month"),
      endDate: dayjs().endOf("month"),
    },
    {
      label: "7 ngày tới",
      startDate: dayjs().add(1, "d").startOf("date"),
      endDate: dayjs().add(7, "d").endOf("date"),
    },
    {
      label: "30 ngày tới",
      startDate: dayjs().add(1, "d").startOf("date"),
      endDate: dayjs().add(30, "d").endOf("date"),
    },
  ];

  const handleSelectDay = (startDate, endDate) => {
    onChange([startDate, endDate]);
    handleCloseSelectDay();
  };

  return (
    <StyledCustomDatePickerRange>
      <div className="date-range">
        <div className="input-wrapper">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="startDate"
              inputFormat="DD/MM/YYYY"
              value={value[0]}
              onChange={(newValue) => handleChange("startDate", newValue)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <Typography className="light-color">đến</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="endDate"
              inputFormat="DD/MM/YYYY"
              minDate={value[0]}
              value={value[1]}
              onChange={(newValue) => handleChange("endDate", newValue)}
              renderInput={(params) => <TextField size="small" {...params} />}
            />
          </LocalizationProvider>
          <KeyboardArrowDown
            className="icon light-color"
            onClick={handleClickSelectDay}
          />
          <Menu
            anchorEl={anchorSelectDay}
            open={Boolean(anchorSelectDay)}
            onClose={handleCloseSelectDay}
          >
            {optionsDatePicker.map((option) => (
              <MenuItem
                key={option.label}
                value={option.label}
                onClick={() =>
                  handleSelectDay(option.startDate, option.endDate)
                }
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </div>
    </StyledCustomDatePickerRange>
  );
};
export default DatePickerRange;
