import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import RoleList from "./RoleList";
import Permission from "./Permission";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";

const Role = () => {
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [reload, setReload] = useState(false);

  const fetchGroupPermissions = async () => {
    try {
      const res = await apis.groupPermission.getGroupPermissions();
      setGroupPermissions(res.groupPermissions);
    } catch (error) {
      handleCallApiError(error);
    }
  };

  useEffect(() => {
    fetchGroupPermissions();
  }, []);

  return (
    <Box margin={2}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <RoleList
            selectedRole={selectedRole}
            setSelectedRole={setSelectedRole}
            reload={reload}
          />
        </Grid>
        <Grid item xs={9}>
          {selectedRole && (
            <Permission
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              groupPermissions={groupPermissions}
              setReload={setReload}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Role;
