import React, { useState } from "react";
import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";

import { INITIAL_PAGING } from "../../constants";
import CustomerList from "./CustomerList";
import CustomerDialog from "./CustomerDialog";

const INIT_CUSTOMER = {
  name: "",
  phoneNumber: "",
  gender: "",
  dateOfBirth: null,
  provinceId: null,
  districtId: null,
  wardId: null,
  note: "",
};

const Customer = () => {
  const [search, setSearch] = useState("");
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [customerDialog, setCustomerDialog] = useState(false);
  const [customer, setCustomer] = useState({});
  const [reload, setReload] = useState(false);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleChangePaging = ({ page, total }) => {
    setPaging({ page: page ?? 1, total: total ?? 0 });
  };

  const handleOpenCustomerDialog = () => {
    setCustomer(INIT_CUSTOMER);
    setCustomerDialog(true);
  };

  const handleCloseCustomerDialog = () => {
    setCustomerDialog(false);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={8} md={8} lg={6}>
            <TextField
              value={search}
              size="small"
              fullWidth
              placeholder={"Tìm kiếm theo tên hoặc số điện thoại"}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
              minwidth="400px"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                color="success"
                sx={{
                  paddingTop: "7px",
                  paddingBottom: "6.5px",
                  marginRight: "5px",
                }}
                onClick={handleOpenCustomerDialog}
              >
                Thêm khách hàng
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <CustomerList
          search={search}
          paging={paging}
          onChangePage={handleChangePaging}
          reload={reload}
        />
      </Box>
      <CustomerDialog
        open={customerDialog}
        handleClose={handleCloseCustomerDialog}
        customer={customer}
        setCustomer={setCustomer}
        handleReload={handleReload}
        dialogType="create"
      />
    </>
  );
};

export default Customer;
