import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import userSlice from "./userSlice";
import roleSlice from "./roleSlice";
import serviceSlice from "./serviceSlice";
import provinceSlice from "./provinceSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    user: userSlice,
    role: roleSlice,
    service: serviceSlice,
    province: provinceSlice,
  },
});
