import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import ViewListIcon from "@mui/icons-material/ViewList";
import PersonIcon from "@mui/icons-material/Person";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StyleIcon from "@mui/icons-material/Style";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import ROUTE from "../constants/route";
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import User from "../pages/User";
import Role from "../pages/Role";
import Permission from "../pages/Permission";
import Service from "../pages/Service";
import Customer from "../pages/Customer";
import CustomerDetail from "../pages/CustomerDetail";
import UserDetail from "../pages/UserDetail";
import Order from "../pages/Order";
import Contribution from "../pages/Contribution";
import WorkTime from "../pages/WorkTime";
import Profile from "../pages/Profile";

const appRoutes = [
  {
    name: "Trang chủ",
    path: ROUTE.DASHBOARD,
    component: Dashboard,
    icon: <HomeIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Đăng nhập",
    path: ROUTE.LOGIN,
    component: Login,
    icon: <HomeIcon />,
    restricted: true,
    isPrivate: false,
    sidebar: false,
  },
  {
    name: "Khách hàng",
    path: ROUTE.CUSTOMER,
    component: Customer,
    icon: <PeopleIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    // name: "Khách hàng",
    path: ROUTE.CUSTOMER_DETAIL,
    component: CustomerDetail,
    // icon: <PeopleIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: "Hóa đơn",
    path: ROUTE.ORDER,
    component: Order,
    icon: <ShoppingCartIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Chốt thẻ",
    path: ROUTE.CONTRIBUTION,
    component: Contribution,
    icon: <StyleIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Thời gian tour",
    path: ROUTE.WORK_TIME,
    component: WorkTime,
    icon: <AccessTimeFilledIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Dịch vụ",
    path: ROUTE.SERVICE,
    component: Service,
    icon: <ViewListIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Nhân sự",
    path: ROUTE.USER,
    component: User,
    icon: <ManageAccountsIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    // name: "Nhân sự",
    path: ROUTE.USER_DETAIL,
    component: UserDetail,
    // icon: <PeopleIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: false,
  },
  {
    name: "Vai trò",
    path: ROUTE.ROLE,
    component: Role,
    icon: <AdminPanelSettingsIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Phân quyền",
    path: ROUTE.PERMISSION,
    component: Permission,
    icon: <VerifiedUserIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
  {
    name: "Hồ sơ cá nhân",
    path: ROUTE.PROFILE,
    component: Profile,
    icon: <PersonIcon />,
    restricted: false,
    isPrivate: true,
    sidebar: true,
  },
];

export default appRoutes;
