import { useEffect, useState } from "react";
import { Box, Grid, Skeleton, Typography } from "@mui/material";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import BarChart from "../../components/Charts/BarChart";
import dayjs from "dayjs";

const Revenue = () => {
  const [loading, setLoading] = useState(false);
  const [revenueByDay, setRevenueByDay] = useState({});
  const [revenueByMonth, setRevenueByMonth] = useState({});

  const fetchRevenueByDay = async () => {
    try {
      const res = await apis.statistic.getRevenue({
        type: "day",
        startDate: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
      const revenue = res.revenue || {};
      setRevenueByDay({
        categories: Object.keys(revenue),
        series: [{ name: "Doanh thu", data: Object.values(revenue) }],
      });
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const fetchRevenueByMonth = async () => {
    try {
      const res = await apis.statistic.getRevenue({
        type: "month",
      });
      const revenue = res.revenue || {};
      setRevenueByMonth({
        categories: Object.keys(revenue),
        series: [{ name: "Doanh thu", data: Object.values(revenue) }],
      });
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const init = async (displayLoading = false) => {
    if (displayLoading) setLoading(true);
    await Promise.all([fetchRevenueByDay(), fetchRevenueByMonth()]);
    if (displayLoading) setLoading(false);
  };

  useEffect(() => {
    init(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      init();
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box>
      <Typography variant="h6" mb={1}>
        Doanh thu
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading ? (
            <>
              <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
              <Skeleton variant="rectangular" sx={{ height: "400px" }} />
            </>
          ) : (
            <BarChart
              title="Doanh thu 14 ngày gần nhất"
              categories={revenueByDay.categories}
              series={revenueByDay.series}
              height="400px"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <>
              <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
              <Skeleton variant="rectangular" sx={{ height: "400px" }} />
            </>
          ) : (
            <BarChart
              title={`Doanh thu 12 tháng gần nhất`}
              categories={revenueByMonth.categories}
              series={revenueByMonth.series}
              height="400px"
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Revenue;
