import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Edit,
  AddCardRounded,
  PersonAdd,
  Autorenew,
  Description,
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import {
  ALL,
  EXPORT_LIMIT,
  INITIAL_PAGING,
  PAGINATION_LIMIT,
} from "../../constants";
import {
  INIT_ORDER,
  ORDER_STATUS,
  ORDER_STATUS_LABEL,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
  PAYMENT_TYPE,
  PAYMENT_TYPE_SHORT_LABEL,
} from "../../constants/order";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";
import { formatMoney } from "../../utils/money";
import { StyledTable } from "../../components/Table/index.style";
import NoData from "../../components/NoData";
import CustomTableFooter from "../../components/Table/TableFooter";
import UpdateOrderDialog from "../CustomerDetail/UpdateOrderDialog";
import ContributorDialog from "../CustomerDetail/ContributorDialog";
import CreateTransactionDialog from "../CustomerDetail/CreateTransactionDialog";
import UpdateTransactionDialog from "../CustomerDetail/UpdateTransactionDialog";
import DatePickerRange from "../../components/PickDateRange";
import { exportExcel } from "../../utils/excel";

const INIT_TRANSACTION = {
  amountOfMoney: 0,
  method: PAYMENT_METHOD.TRANSFER,
  note: "",
};

const INIT_DATE = [dayjs().startOf("month"), dayjs().endOf("month")];

const Row = ({
  row,
  index,
  handleOpenUpdateOrderDialog,
  handleOpenContributorDialog,
  handleOpenCreateTransactionDialog,
  handleOpenUpdateTransactionDialog,
}) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (row.status === ORDER_STATUS.CANCELLED) {
      setOpen(false);
      return;
    }
    setOpen(!open);
  };

  const renderCustomerName = (customer) => {
    return (
      <Typography
        sx={{ cursor: "pointer" }}
        color="primary"
        fontSize="13px"
        onClick={() => history.push(`/customer/${customer?.id}`)}
      >
        {customer?.name || ""}
      </Typography>
    );
  };

  const renderItemsName = (orderItems) => {
    return (
      <>
        {orderItems.map((orderItem) => (
          <Typography fontSize="13px" key={orderItem.id}>
            {orderItem.name}
          </Typography>
        ))}
      </>
    );
  };

  const renderPaymentType = (row) => {
    return (
      <>
        <Typography fontSize="13px">
          {PAYMENT_TYPE_SHORT_LABEL[row.paymentType]}
        </Typography>
        {row.status === ORDER_STATUS.INCOMPLETE &&
          row.paymentType === PAYMENT_TYPE.PARTIAL_PAYMENT && (
            <Typography fontSize="13px">
              (
              {formatMoney(
                row?.transactions?.reduce(
                  (acc, transaction) => acc + transaction.amountOfMoney,
                  0
                ) || 0
              )}
              )
            </Typography>
          )}
      </>
    );
  };

  const renderOrderStatus = (status) => {
    switch (status) {
      case ORDER_STATUS.DONE:
        return (
          <Typography sx={{ color: COLOR.success }} fontSize="13px">
            {ORDER_STATUS_LABEL[status]}
          </Typography>
        );

      case ORDER_STATUS.INCOMPLETE:
        return (
          <Typography sx={{ color: COLOR.error }} fontSize="13px">
            {ORDER_STATUS_LABEL[status]}
          </Typography>
        );

      case ORDER_STATUS.CANCELLED:
        return (
          <Typography sx={{ color: COLOR.dark }} fontSize="13px">
            {ORDER_STATUS_LABEL[status]}
          </Typography>
        );

      default:
        return <>status</>;
    }
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: [ORDER_STATUS.DONE, ORDER_STATUS.INCOMPLETE].includes(
            row.status
          )
            ? "pointer"
            : "",
        }}
        className="body-row"
        onClick={handleOpen}
      >
        <TableCell className="body-cell-collapse" align="center">
          {index + 1}
        </TableCell>
        <TableCell className="body-cell-collapse" component="th" scope="row">
          {renderCustomerName(row.customer || {})}
        </TableCell>
        <TableCell className="body-cell-collapse" component="th" scope="row">
          {renderItemsName(row.orderItems)}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {formatMoney(row.total)}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {renderPaymentType(row)}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {dayjs(row.createdAt).format("HH:mm DD/MM/YYYY")}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {row.note}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {renderOrderStatus(row.status)}
        </TableCell>
        <TableCell className="body-cell-collapse">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title="Cập nhật thông tin">
              <IconButton onClick={(e) => handleOpenUpdateOrderDialog(row, e)}>
                <Edit sx={{ color: COLOR.purple }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cập nhật người chốt thẻ">
              <span>
                <IconButton
                  onClick={(e) => handleOpenContributorDialog(row, e)}
                  disabled={row.status === ORDER_STATUS.CANCELLED}
                >
                  <PersonAdd
                    sx={{
                      color:
                        row.status === ORDER_STATUS.CANCELLED
                          ? ""
                          : COLOR.orange,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Thanh toán bổ sung">
              <span>
                <IconButton
                  onClick={(e) => handleOpenCreateTransactionDialog(row, e)}
                  disabled={[
                    ORDER_STATUS.DONE,
                    ORDER_STATUS.CANCELLED,
                  ].includes(row.status)}
                >
                  <AddCardRounded
                    color={
                      [ORDER_STATUS.DONE, ORDER_STATUS.CANCELLED].includes(
                        row.status
                      )
                        ? ""
                        : "error"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: TRANSPARENT_COLOR.light,
          }}
          colSpan={9}
        >
          <Collapse
            in={
              [ORDER_STATUS.DONE, ORDER_STATUS.INCOMPLETE].includes(
                row.status
              ) && open
            }
            timeout="auto"
            unmountOnExit
          >
            <Box my={1}>
              <Typography fontWeight={500}>Chi tiết thanh toán</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Lượt</TableCell>
                    <TableCell align="left">Số tiền</TableCell>
                    <TableCell align="left">Phương thức</TableCell>
                    <TableCell align="left">Người tạo</TableCell>
                    <TableCell align="left">Ghi chú</TableCell>
                    <TableCell align="center">Trạng thái</TableCell>
                    <TableCell align="center">...</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.transactions.map((transaction, index) => (
                    <TableRow hover key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {formatMoney(transaction.amountOfMoney)}
                      </TableCell>
                      <TableCell align="left">
                        {PAYMENT_METHOD_LABEL[transaction.method]}
                      </TableCell>
                      <TableCell align="left">
                        {transaction.creatorName}
                      </TableCell>
                      <TableCell align="left">{transaction.note}</TableCell>
                      <TableCell align="center">
                        {renderOrderStatus(transaction.status)}
                      </TableCell>
                      <TableCell>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Tooltip title="Cập nhật thanh toán">
                            <span>
                              <IconButton
                                onClick={() =>
                                  handleOpenUpdateTransactionDialog(row, {
                                    ...transaction,
                                    index,
                                  })
                                }
                                disabled={!!transaction.isFirst}
                              >
                                <Edit
                                  sx={{
                                    color: transaction.isFirst
                                      ? ""
                                      : COLOR.purple,
                                  }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Order = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(ALL);
  const [date, setDate] = useState(INIT_DATE);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [order, setOrder] = useState(INIT_ORDER);
  const [updateOrderDialog, setUpdateOrderDialog] = useState(false);
  const [contributorDialog, setContributorDialog] = useState(false);
  const [transaction, setTransaction] = useState(INIT_TRANSACTION);
  const [createTransactionDialog, setCreateTransactionDialog] = useState(false);
  const [updateTransactionDialog, setUpdateTransactionDialog] = useState(false);
  const [exporting, setExporting] = useState(false);

  const fetchOrders = async (displayLoading = false) => {
    if (displayLoading) setLoading(true);
    try {
      const [startDate, endDate] = date;
      const condition = {
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        status: status !== ALL ? status : null,
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.order.getOrders(condition);
      setOrders(res.orders);
      setPaging((prev) => ({ ...prev, total: res.total || 0 }));
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setStatus(value);
    handleChangePage(INITIAL_PAGING.page);
  };

  const handleChangeDate = (newValue) => {
    setDate(newValue);
    handleChangePage(INITIAL_PAGING.page);
  };

  const handleRefresh = () => {
    setDate(INIT_DATE);
    setPaging(INITIAL_PAGING);
  };

  const heads = {
    no: "STT",
    customerName: "Khách hàng",
    customerPhoneNumber: "Số điện thoại",
    services: "Dịch vụ sử dụng",
    status: "Trạng thái",
    total: "Tổng số tiền",
    paymentType: "Loại thanh toán",
    paid: "Đã thanh toán",
    createdAt: "Thời gian tạo",
    creatorName: "Người tạo",
    completedAt: "Thời gian hoàn thành",
    cancelledAt: "Thời gian hủy",
    cancellerName: "Người hủy",
  };

  const handleExportExcel = async () => {
    try {
      setExporting(true);
      const [startDate, endDate] = date;
      const condition = {
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        status: status !== ALL ? status : null,
        limit: EXPORT_LIMIT,
      };

      const total = paging.total;
      const turns = Math.ceil(total / EXPORT_LIMIT);

      let readableData = [];
      for (let page = 0; page < turns; page++) {
        condition.offset = page * EXPORT_LIMIT;
        const res = await apis.order.getOrders(condition);

        if (res.orders.length === 0) {
          setLoading(false);
          toast.warn("Không có hoá đơn nào");
          return;
        }

        readableData = readableData.concat(
          res.orders.map((row, index) => ({
            no: page * EXPORT_LIMIT + index + 1,
            customerName: row.customer.name,
            customerPhoneNumber: row.customer.phoneNumber,
            services: row.orderItems
              .map((orderItem) => orderItem.name)
              .join(", "),
            status: ORDER_STATUS_LABEL[row.status],
            total: row.total,
            paymentType: PAYMENT_TYPE_SHORT_LABEL[row.paymentType],
            paid:
              row?.transactions?.reduce(
                (acc, transaction) => acc + transaction.amountOfMoney,
                0
              ) || 0,
            createdAt: dayjs(row.createdAt).format("HH:mm DD/MM/YYYY"),
            creatorName: row.creatorName,
            completedAt: row.completedAt
              ? dayjs(row.completedAt).format("HH:mm DD/MM/YYYY")
              : "",
            cancelledAt: row.cancelledAt
              ? dayjs(row.cancelledAt).format("HH:mm DD/MM/YYYY")
              : "",
            cancellerName: row.cancellerName,
          }))
        );
      }

      const csvData = readableData.map((data) => {
        const mappingData = {};
        Object.keys(heads).forEach(
          (key) => (mappingData[heads[key]] = data[key])
        );
        return mappingData;
      });
      const fileName = `Hoa_don_${dayjs(startDate).format(
        "DD.MM.YYYY"
      )}_${dayjs(endDate).format("DD.MM.YYYY")}.xlsx`;

      exportExcel(csvData, fileName, Object.values(heads));
    } catch (error) {
      handleCallApiError(error);
    }
    setExporting(false);
  };

  const handleOpenUpdateOrderDialog = (row, e) => {
    e.stopPropagation();
    setOrder(row);
    setUpdateOrderDialog(true);
  };

  const handleCloseUpdateOrderDialog = () => {
    setUpdateOrderDialog(false);
    setOrder(INIT_ORDER);
  };

  const handleOpenContributorDialog = (row, e) => {
    e.stopPropagation();
    setOrder(row);
    setContributorDialog(true);
  };

  const handleCloseContributorDialog = () => {
    setContributorDialog(false);
    setOrder(INIT_ORDER);
  };

  const handleOpenCreateTransactionDialog = (order, e) => {
    e.stopPropagation();
    const missingPrice =
      order.total -
      order.transactions.reduce(
        (acc, transaction) =>
          transaction.status === ORDER_STATUS.DONE
            ? acc + transaction.amountOfMoney
            : acc,
        0
      );
    setTransaction({
      ...INIT_TRANSACTION,
      amountOfMoney: missingPrice,
    });

    setOrder(order);
    setCreateTransactionDialog(true);
  };

  const handleCloseCreateTransactionDialog = () => {
    setCreateTransactionDialog(false);
    setOrder(INIT_ORDER);
    setTransaction(INIT_TRANSACTION);
  };

  const handleOpenUpdateTransactionDialog = (order, transaction) => {
    setTransaction(transaction);
    setOrder(order);
    setUpdateTransactionDialog(true);
  };

  const handleCloseUpdateTransactionDialog = () => {
    setUpdateTransactionDialog(false);
    setOrder(INIT_ORDER);
    setTransaction(INIT_TRANSACTION);
  };

  useEffect(() => {
    fetchOrders(true);
  }, [paging.page, status, date]);

  return (
    <Box p={2}>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={3} md={3} lg={2}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Trạng thái</InputLabel>
            <Select
              labelId="status-select-label"
              value={status}
              onChange={handleChangeStatus}
              size="small"
              label="Trạng thái"
            >
              <MenuItem value={ALL}>Tất cả</MenuItem>
              {Object.keys(ORDER_STATUS_LABEL).map((key) => (
                <MenuItem key={key} value={key}>
                  {ORDER_STATUS_LABEL[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5}>
          <Box display="flex">
            <DatePickerRange value={date} onChange={handleChangeDate} />
            <Tooltip title="Đặt lại">
              <IconButton aria-label="refresh" onClick={handleRefresh}>
                <Autorenew />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} md={3} lg={5}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <LoadingButton
              color="success"
              variant="outlined"
              startIcon={<Description />}
              loading={exporting}
              disabled={loading || !paging.total}
              onClick={handleExportExcel}
            >
              Xuất Excel
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <StyledTable component={Box}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">STT</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Khách hàng</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Dịch vụ</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Số tiền (VNĐ)</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Thanh toán</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Ngày tạo</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Ghi chú</Typography>
              </TableCell>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">Trạng thái</Typography>
              </TableCell>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">...</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="body-row">
                <TableCell colSpan={7}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              orders.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  handleOpenUpdateOrderDialog={handleOpenUpdateOrderDialog}
                  handleOpenContributorDialog={handleOpenContributorDialog}
                  handleOpenCreateTransactionDialog={
                    handleOpenCreateTransactionDialog
                  }
                  handleOpenUpdateTransactionDialog={
                    handleOpenUpdateTransactionDialog
                  }
                />
              ))
            )}
          </TableBody>
        </Table>
        {orders && orders.length ? (
          <CustomTableFooter
            total={paging.total}
            page={paging.page}
            onChangePage={handleChangePage}
          />
        ) : (
          !loading && <NoData />
        )}
      </StyledTable>
      <UpdateOrderDialog
        open={updateOrderDialog}
        handleClose={handleCloseUpdateOrderDialog}
        order={order}
        reload={fetchOrders}
      />
      <ContributorDialog
        open={contributorDialog}
        handleClose={handleCloseContributorDialog}
        order={order}
        reload={fetchOrders}
      />
      <CreateTransactionDialog
        open={createTransactionDialog}
        handleClose={handleCloseCreateTransactionDialog}
        order={order}
        transaction={transaction}
        setTransaction={setTransaction}
        reload={fetchOrders}
      />
      <UpdateTransactionDialog
        open={updateTransactionDialog}
        handleClose={handleCloseUpdateTransactionDialog}
        order={order}
        transaction={transaction}
        reload={fetchOrders}
      />
    </Box>
  );
};

export default Order;
