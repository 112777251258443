import api from "./api";

const getOrderItems = async (params) => {
  const response = await api({
    method: "GET",
    url: "/order-items",
    params,
  });

  return response;
};

const updateOrderItem = async (orderItemId, note) => {
  const response = await api({
    method: "PUT",
    url: `/order-items/${orderItemId}`,
    data: { note },
  });

  return response;
};

export { getOrderItems, updateOrderItem };
