import api from "./api";

const getGroupPermissions = async () => {
  const response = await api({
    method: "GET",
    url: "/group-permissions",
  });

  return response;
};

const createGroupPermission = async (data) => {
  const response = await api({
    method: "POST",
    url: "/group-permissions",
    data,
  });

  return response;
};

const updateGroupPermission = async (groupPermissionId, data) => {
  const response = await api({
    method: "PUT",
    url: `/group-permissions/${groupPermissionId}`,
    data,
  });

  return response;
};

const deleteGroupPermission = async (groupPermissionId) => {
  const response = await api({
    method: "DELETE",
    url: `/group-permissions/${groupPermissionId}`,
  });

  return response;
};

export {
  getGroupPermissions,
  createGroupPermission,
  updateGroupPermission,
  deleteGroupPermission,
};
