import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as uuid from "uuid";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";
import { Add, Delete } from "@mui/icons-material";

const INIT_CONTRIBUTOR = {
  userId: null,
  userName: "",
};

const ContributorDialog = ({ open, handleClose, order, reload }) => {
  const [loading, setLoading] = useState(false);
  const [handling, setHandling] = useState(false);
  const [users, setUsers] = useState([]);
  const [orderItems, setOrderItems] = useState([]);

  const handleCloseDialog = () => {
    handleClose();
    setOrderItems([]);
  };

  const handleChangeContributor = (
    newValue,
    orderItemIndex,
    contributorIndex
  ) => {
    let orderItemsTmp = [...orderItems];
    const contributorsTmp = [...orderItemsTmp[orderItemIndex].contributors];

    const userId = newValue ? parseInt(newValue, 10) : null;
    const user = users.find((u) => u.id === userId);

    contributorsTmp[contributorIndex] = {
      ...contributorsTmp[contributorIndex],
      userId,
      userName: user ? `${user.name} - ${user.phoneNumber}` : "",
    };

    orderItemsTmp[orderItemIndex] = {
      ...orderItemsTmp[orderItemIndex],
      contributors: contributorsTmp,
    };

    setOrderItems(orderItemsTmp);
  };

  const handleAddContributor = (index) => {
    let orderItemsTmp = [...orderItems];
    orderItemsTmp[index] = {
      ...orderItemsTmp[index],
      contributors: [...orderItemsTmp[index].contributors, INIT_CONTRIBUTOR],
    };
    setOrderItems(orderItemsTmp);
  };

  const handleDeleteContributor = (orderItemIndex, contributorIndex) => {
    let orderItemsTmp = [...orderItems];
    let contributorsTmp = [...orderItemsTmp[orderItemIndex].contributors];
    contributorsTmp.splice(contributorIndex, 1);
    orderItemsTmp[orderItemIndex] = {
      ...orderItemsTmp[orderItemIndex],
      contributors: contributorsTmp,
    };
    setOrderItems(orderItemsTmp);
  };

  const formatOrderItemData = () =>
    orderItems.map(({ id: orderItemId, contributors = [] }) => {
      const newContributors = [];
      contributors.forEach(({ userId, userName }) => {
        if (
          userId &&
          !newContributors
            .map((contributor) => contributor.userId)
            .includes(userId)
        ) {
          newContributors.push({
            userId,
            userName,
          });
        }
      });

      return {
        orderItemId,
        contributors: newContributors,
      };
    });

  const handleUpdateContributors = async () => {
    setHandling(true);
    try {
      await apis.order.assignContributors(order.id, {
        orderItems: formatOrderItemData(),
      });
      handleCloseDialog();
      reload();
      toast.success("Cập nhật người chốt thẻ thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setHandling(false);
  };

  const renderUserName = (userId) => {
    const user = users.find((u) => u.id === userId);
    if (!user) return "";
    return `${user.name} - ${user.phoneNumber}`;
  };

  const fetchUsers = async () => {
    try {
      const res = await apis.user.getUsers({ active: true });
      setUsers(res.users);
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const initOrderItems = () => {
    const newOrderItems = [];
    const { orderItems = [] } = order;
    orderItems.forEach((orderItem) => {
      const {
        id,
        name: serviceName,
        contributors: currContributors = [],
      } = orderItem;
      const newContributors = [];
      if (currContributors.length) {
        newContributors.push(...currContributors);
      } else {
        newContributors.push(INIT_CONTRIBUTOR);
      }
      newOrderItems.push({
        id,
        serviceName,
        contributors: newContributors,
      });
    });
    setOrderItems(newOrderItems);
  };

  const init = async () => {
    setLoading(true);
    await Promise.all([initOrderItems(), fetchUsers()]);
    setLoading(false);
  };

  useEffect(() => {
    if (open) init();
  }, [open]);

  return (
    <Dialog
      title="Cập nhật người chốt thẻ"
      subTitle={
        order?.orderItems?.map((orderItem) => orderItem.name).join(", ") || ""
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          orderItems.map(
            ({ serviceName, contributors = [] }, orderItemIndex) => (
              <Box key={uuid.v4()} mb={2}>
                <Typography fontWeight={500} mb={1} fontSize="16px">
                  {orderItemIndex + 1}. {serviceName}
                </Typography>
                {contributors.map(
                  (contributor, contributorIndex, currContributors) => (
                    <Grid
                      key={uuid.v4()}
                      container
                      alignItems="center"
                      spacing={1}
                      mb={1}
                    >
                      <Grid item xs={12} sm={3}>
                        <Typography fontWeight={500}>
                          Người chốt thẻ {contributorIndex + 1}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={8}>
                        <Autocomplete
                          size="small"
                          value={contributor.userId}
                          options={users.map((user) => user.id)}
                          getOptionLabel={(option) => renderUserName(option)}
                          filterSelectedOptions
                          onChange={(event, newValue) =>
                            handleChangeContributor(
                              newValue,
                              orderItemIndex,
                              contributorIndex
                            )
                          }
                          renderOption={(props, key) => (
                            <MenuItem value={key} {...props}>
                              {renderUserName(key)}
                            </MenuItem>
                          )}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={1}
                        sx={{ textAlign: "end" }}
                        alignSelf="flex-end"
                      >
                        {currContributors.length > 1 && (
                          <Tooltip title="Xóa người chốt thẻ">
                            <IconButton
                              aria-label="delete"
                              color="error"
                              onClick={() =>
                                handleDeleteContributor(
                                  orderItemIndex,
                                  contributorIndex
                                )
                              }
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  )
                )}
                {contributors.length < 3 && (
                  <Button
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => handleAddContributor(orderItemIndex)}
                  >
                    Thêm
                  </Button>
                )}
              </Box>
            )
          )
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>

        <LoadingButton
          variant="contained"
          color="success"
          loading={handling}
          onClick={handleUpdateContributors}
        >
          Lưu thay đổi
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContributorDialog;
