import axios from "axios";
import camelCase from "camelcase-keys";
import snakeCase from "snakecase-keys";
import { API_URL } from "../configs";

const axiosClient = axios.create({
  baseURL: `${API_URL}/api`,
  responseType: "json",
  timeout: 20 * 1000,
});

axiosClient.interceptors.request.use(
  (config) => {
    if (config.data) config.data = snakeCase(config.data);
    if (config.params) config.params = snakeCase(config.params);
    return config;
  },
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) =>
    camelCase(response.data, { deep: true, stopPaths: ["revenue"] }),
  (error) => Promise.reject(error)
);

export default axiosClient;
