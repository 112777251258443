import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Autorenew } from "@mui/icons-material";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { TOOLTIP_BOX_SHADOW } from "../../styles/config";
import DatePickerRange from "../../components/PickDateRange";
import { INITIAL_PAGING, PAGINATION_LIMIT } from "../../constants";
import Table from "../../components/Table";

const INIT_LOADING = {
  statistic: false,
  detail: false,
};
const INIT_DATE = [dayjs().startOf("month"), dayjs().endOf("month")];

const UserWorkTime = ({ user }) => {
  const [loading, setLoading] = useState(INIT_LOADING);
  const [workTime, setWorkTime] = useState(0);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [date, setDate] = useState(INIT_DATE);
  const [histories, setHistories] = useState([]);

  const fetchWorkTime = async () => {
    setLoading((prev) => ({ ...prev, statistic: true }));
    try {
      const res = await apis.statistic.getUserWorkTime(user.id, {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
      setWorkTime(res.workTime);
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading((prev) => ({ ...prev, statistic: false }));
  };

  const fetchHistories = async () => {
    setLoading((prev) => ({ ...prev, detail: true }));
    try {
      const [startDate, endDate] = date;
      const condition = {
        userId: user.id,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.history.getHistories(condition);
      setHistories(res.histories);
      setPaging((prev) => ({ ...prev, total: res.total || 0 }));
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading((prev) => ({ ...prev, detail: false }));
  };

  const handleRefresh = () => {
    setDate(INIT_DATE);
    setPaging(INITIAL_PAGING);
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
  };

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    handleChangePage(INITIAL_PAGING.page);
  };

  useEffect(() => {
    fetchWorkTime();
  }, []);

  useEffect(() => {
    fetchHistories();
  }, [paging.page, date]);

  const columns = [
    {
      field: "customerName",
      title: "Khách hàng",
      align: "left",
    },
    {
      field: "serviceName",
      title: "Dịch vụ",
      align: "left",
    },
    {
      title: "Buổi",
      align: "left",
      render: (row) => (row.session < 10 ? `0${row.session}` : row.session),
    },
    {
      title: "Thời lượng",
      align: "left",
      render: (row) => `${row.serviceDuration} phút`,
    },
    {
      field: "creatorName",
      title: "Người tạo",
      align: "left",
    },
    {
      title: "Ngày tạo",
      align: "left",
      render: (row) => dayjs(row.createdAt).format("HH:mm DD-MM-YYYY"),
    },
  ];

  return (
    <Box>
      <Typography variant="h6" mb={1}>
        Tổng quan
      </Typography>
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <Card sx={{ boxShadow: TOOLTIP_BOX_SHADOW }}>
            <CardContent>
              {loading.statistic ? (
                <>
                  <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 2 }} />
                  <Skeleton variant="rectangular" sx={{ height: "28px" }} />
                </>
              ) : (
                <>
                  <Typography variant="body1" color="text.secondary" mb={2}>
                    Tổng thời gian tour
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    textAlign="right"
                    mb={0}
                  >
                    {`${workTime} phút`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        my={2}
      >
        <Typography variant="h6">Danh sách chốt thẻ</Typography>
        <Box display="flex">
          <DatePickerRange value={date} onChange={handleChangeDate} />
          <Tooltip title="Đặt lại">
            <IconButton aria-label="refresh" onClick={handleRefresh}>
              <Autorenew />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Table
        data={histories}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading.detail}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </Box>
  );
};

export default UserWorkTime;
