import api from "./api";

const createOrder = async (data) => {
  const response = await api({
    method: "POST",
    url: "/orders",
    data,
  });

  return response;
};

const getOrders = async (params) => {
  const response = await api({
    method: "GET",
    url: "/orders",
    params,
  });

  return response;
};

const updateOrder = async (orderId, data) => {
  const response = await api({
    method: "PUT",
    url: `/orders/${orderId}`,
    data,
  });

  return response;
};

const assignContributors = async (orderId, contributors) => {
  const response = await api({
    method: "PUT",
    url: `/orders/${orderId}/assign-contributors`,
    data: contributors,
  });

  return response;
};

const addTransaction = async (orderId, transaction) => {
  const response = await api({
    method: "PUT",
    url: `/orders/${orderId}/add-transaction`,
    data: transaction,
  });

  return response;
};

const updateTransaction = async (orderId, transaction) => {
  const response = await api({
    method: "PUT",
    url: `/orders/${orderId}/update-transaction`,
    data: transaction,
  });

  return response;
};

export {
  createOrder,
  getOrders,
  updateOrder,
  assignContributors,
  addTransaction,
  updateTransaction,
};
