import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";

export const StyledUserDetail = styled(Box)`
  padding: 16px;

  .nav-tabs {
    width: 100%;
    background-color: ${TRANSPARENT_COLOR.light};

    .MuiTabs-indicator {
      background-color: ${COLOR.indicator};
    }
  }

  .tab-panel {
    padding: 1.25rem 0;
  }
`;
