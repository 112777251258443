import React from "react";
import Chart from "react-apexcharts";
import { Typography } from "@mui/material";
import { formatMoney } from "../../utils/money";

const BarChart = ({ title, series = [], categories = [], height }) => {
  const options = {
    chart: {
      type: "bar",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: { categories },
    yaxis: {
      title: {
        text: "Đơn vị: triệu VNĐ",
        style: {
          fontWeight: 300,
        },
      },
      labels: {
        formatter: function (val) {
          return (val / 1000000).toFixed(1);
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return formatMoney(val) + " VNĐ";
        },
      },
    },
  };

  return (
    <>
      <Typography fontWeight={500} mb={1}>
        {title}
      </Typography>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={height ? height : ""}
      />
    </>
  );
};

export default BarChart;
