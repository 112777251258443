import React from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  Autocomplete,
  MenuItem,
  TextField,
  Tooltip,
  IconButton,
  Button,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

import Title from "../Title";
import { TRANSPARENT_COLOR } from "../../styles/color";
import { formatMoney } from "../../utils/money";
import { INIT_ORDER_ITEM, INIT_ORDER_ITEM_ERROR } from "../../constants/order";
import { getTotalMoney } from "./helper";
import {
  validateRequired as checkRequired,
  validateInt as checkInt,
} from "../../utils/validate";
import CurrencyInput from "../CurrencyInput";

const ChooseOrderItem = ({
  orderItems,
  setOrderItems,
  orderItemsError,
  setOrderItemsError,
  validateDiscount,
  validateNumberOfSessions,
}) => {
  const services = useSelector((state) => state.service);

  const renderServiceName = (serviceId) => {
    const service = services.find((sv) => sv.id === serviceId);
    return `${service?.name || ""}`;
  };

  const handleChangeError = (index, obj) => {
    let itemsError = [...orderItemsError];
    itemsError[index] = { ...itemsError[index], ...obj };
    setOrderItemsError(itemsError);
  };

  const handleChangeService = (newValue, index) => {
    let orderItemsTmp = [...orderItems];
    const serviceId = newValue ? parseInt(newValue, 10) : null;
    orderItemsTmp[index] = { ...orderItemsTmp[index], serviceId };
    const service = services.find((vc) => vc.id === serviceId);
    let discount = 0;
    let numberOfSessions = 1;
    if (service) {
      numberOfSessions = service.numberOfSessions;
      discount = service.discount;
      if (orderItemsTmp[index].discountPercent)
        discount =
          (service?.price *
            parseInt(orderItemsTmp[index].discountPercent, 10)) /
          100;
    }

    orderItemsTmp[index] = {
      ...orderItemsTmp[index],
      discount,
      numberOfSessions,
    };
    setOrderItems(orderItemsTmp);
    handleChangeError(index, { serviceId: false });
  };

  const handleChangeDiscount = (event, index) => {
    let orderItemsTmp = [...orderItems];
    orderItemsTmp[index] = {
      ...orderItemsTmp[index],
      discount: event.target.value,
      discountPercent: 0,
    };

    setOrderItems(orderItemsTmp);
    handleChangeError(index, { discount: false });
  };

  const handleChangeNumberOfSessions = (event, index) => {
    let orderItemsTmp = [...orderItems];
    orderItemsTmp[index] = {
      ...orderItemsTmp[index],
      numberOfSessions: event.target.value,
    };

    setOrderItems(orderItemsTmp);
    handleChangeError(index, { numberOfSessions: false });
  };

  const handleAddOrderItem = () => {
    setOrderItems((prev) => [...prev, INIT_ORDER_ITEM]);
    setOrderItemsError((prev) => [...prev, INIT_ORDER_ITEM_ERROR]);
  };

  const handleDeleteOrderItem = (index) => {
    const orderItemsTmp = [...orderItems];
    orderItemsTmp.splice(index, 1);
    setOrderItems(orderItemsTmp);
    const orderItemsErrorTmp = [...orderItemsError];
    orderItemsErrorTmp.splice(index, 1);
    setOrderItemsError(orderItemsErrorTmp);
  };

  const handleChangeDiscountPercent = (event, index) => {
    let orderItemsTmp = [...orderItems];
    let discount = orderItemsTmp[index].discount;
    if (event.target.value && orderItemsTmp[index].serviceId) {
      const service = services.find(
        (sv) => sv.id === orderItemsTmp[index].serviceId
      );
      const intValue = parseInt(event.target.value, 10);
      discount = (service?.price * intValue) / 100;
    }
    orderItemsTmp[index] = {
      ...orderItemsTmp[index],
      discount,
      discountPercent: event.target.value,
    };

    setOrderItems(orderItemsTmp);
    handleChangeError(index, { discount: false, discountPercent: false });
  };

  const validateDiscountPercent = () => {
    let isValid = true;
    const tmpOrderItemsError = [...orderItemsError];
    orderItems.forEach(({ serviceId, discountPercent }, index) => {
      if (
        serviceId &&
        checkRequired(discountPercent) &&
        !checkInt(discountPercent, 0, 100)
      ) {
        isValid = false;
        tmpOrderItemsError[index] = {
          ...tmpOrderItemsError[index],
          discountPercent: "Không hợp lệ",
        };
      }
    });
    setOrderItemsError(tmpOrderItemsError);
    return isValid;
  };

  const renderTotalPrice = () =>
    `${formatMoney(getTotalMoney(orderItems, services))} VNĐ`;

  return (
    <Box mb={1}>
      <Box mb={2}>
        <Title title="Chọn dịch vụ" bgColor={TRANSPARENT_COLOR.primary} />
        {orderItems.map((orderItem, index, currArray) => (
          <Grid key={index} container spacing={2} mb={2}>
            <Grid item xs={12} sm={12} md={5}>
              <Typography fontWeight={500} mb={1}>
                Dịch vụ {index + 1}:
              </Typography>
              <Autocomplete
                size="small"
                value={orderItem.serviceId}
                options={services.map((service) => service.id)}
                getOptionLabel={(option) => renderServiceName(option)}
                filterSelectedOptions
                onChange={(event, newValue) =>
                  handleChangeService(newValue, index)
                }
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderServiceName(key)}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!orderItemsError[index].serviceId}
                    helperText={orderItemsError[index].serviceId}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Typography fontWeight={500} mb={1}>
                Số buổi:
              </Typography>
              <TextField
                error={!!orderItemsError[index].numberOfSessions}
                helperText={orderItemsError[index].numberOfSessions}
                value={orderItem.numberOfSessions}
                size="small"
                fullWidth
                onChange={(event) => handleChangeNumberOfSessions(event, index)}
                onBlur={validateNumberOfSessions}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Typography fontWeight={500} mb={1}>
                Khuyến mãi (%):
              </Typography>
              <TextField
                error={!!orderItemsError[index].discountPercent}
                helperText={orderItemsError[index].discountPercent}
                value={orderItem.discountPercent}
                size="small"
                fullWidth
                onChange={(event) => handleChangeDiscountPercent(event, index)}
                onBlur={validateDiscountPercent}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <Typography fontWeight={500} mb={1}>
                Khuyến mãi (VNĐ):
              </Typography>
              <CurrencyInput
                value={orderItem.discount}
                onChange={(event) => handleChangeDiscount(event, index)}
                onBlur={validateDiscount}
                error={!!orderItemsError[index].discount}
                helperText={orderItemsError[index].discount}
              />
              {/* <TextField
                error={!!orderItemsError[index].discount}
                helperText={orderItemsError[index].discount}
                value={orderItem.discount}
                size="small"
                fullWidth
                onChange={(event) => handleChangeDiscount(event, index)}
                onBlur={validateDiscount}
                type="number"
              /> */}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={1}
              sx={{ textAlign: "end" }}
              alignSelf="flex-end"
            >
              {orderItems.length > 1 && (
                <Tooltip title="Xóa dịch vụ">
                  <IconButton
                    aria-label="delete"
                    color="error"
                    onClick={() => handleDeleteOrderItem(index)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        ))}
        {orderItems.length < 3 && (
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={handleAddOrderItem}
          >
            Thêm
          </Button>
        )}
      </Box>
      <Typography align="right" fontWeight={500} fontSize="18px">
        Tổng: {renderTotalPrice()}
      </Typography>
    </Box>
  );
};

export default ChooseOrderItem;
