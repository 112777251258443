import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import debounce from "../../utils/debounce";
import UserDialog from "./UserDialog";
import { ALL, PAGINATION_LIMIT } from "../../constants";

const UserList = ({ search, paging, onChangePage, roleId, reload, roles }) => {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [userDialog, setUserDialog] = useState(false);

  const handleChangePage = (page) => onChangePage({ ...paging, page });

  const handleOpenUserDialog = (row) => {
    setUser({
      ...row,
      dateOfBirth: row.dateOfBirth
        ? dayjs(row.dateOfBirth, "DD/MM/YYYY")
        : null,
    });
    setUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setUserDialog(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const condition = {
        search,
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
        roleId: roleId === ALL ? undefined : roleId,
      };
      const res = await apis.user.getUsers(condition);
      setUsers(res.users);
      onChangePage({ ...paging, total: res?.total || 0 });
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const redirectUserDetailPage = (userId) => history.push(`/user/${userId}`);

  const columns = [
    {
      title: "Họ và tên",
      align: "left",
      render: (row) => (
        <Typography
          sx={{ cursor: "pointer" }}
          color="primary"
          fontSize="13px"
          onClick={() => redirectUserDetailPage(row.id)}
        >
          {row.name}
        </Typography>
      ),
    },
    {
      field: "phoneNumber",
      title: "SĐT",
      align: "left",
    },
    {
      field: "email",
      title: "Email",
      align: "left",
    },

    {
      field: "dateOfBirth",
      title: "Ngày sinh",
      align: "left",
    },
    {
      title: "Vai trò",
      align: "left",
      render: (row) => row.role.name,
    },
    {
      title: "Trạng thái",
      align: "center",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success }} fontSize="13px">
              Hoạt động
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.danger }} fontSize="13px">
              Không hoạt động
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title="Chỉnh sửa">
              <span>
                <IconButton
                  onClick={() => handleOpenUserDialog(row)}
                  disabled={!!row.role.isMasterRole}
                >
                  <Edit
                    sx={{
                      color: row.role.isMasterRole ? "" : COLOR.purple,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  useEffect(() => {
    debounce(fetchUsers, 500)(search);
  }, [paging.page, search, reload, roleId]);

  return (
    <>
      <Table
        data={users}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <UserDialog
        open={userDialog}
        handleClose={handleCloseUserDialog}
        user={user}
        setUser={setUser}
        handleReload={fetchUsers}
        roles={roles}
        dialogType="update"
      />
    </>
  );
};

export default UserList;
