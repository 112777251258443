const ROUTE = {
  DASHBOARD: "/",
  LOGIN: "/login",
  USER: "/user",
  USER_DETAIL: "/user/:userId",
  ROLE: "/role",
  PERMISSION: "/permission",
  SERVICE: "/service",
  CUSTOMER: "/customer",
  CUSTOMER_DETAIL: "/customer/:customerId",
  ORDER: "/order",
  CONTRIBUTION: "/contribution",
  WORK_TIME: "/work-time",
  PROFILE: "/profile",
};

export default ROUTE;
