import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import Dialog from "../../components/Dialog";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import { COLOR } from "../../styles/color";
import { setServices } from "../../stores/serviceSlice";
import CurrencyInput from "../../components/CurrencyInput";

const INIT_ERROR_SERVICE = {
  name: "",
  numberOfSessions: "",
  price: "",
  discount: "",
  duration: "",
};

const ServiceDialog = ({
  open,
  handleClose,
  service,
  setService,
  handleReload,
  dialogType,
}) => {
  const [serviceError, setServiceError] = useState(INIT_ERROR_SERVICE);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChangeService = (event, field) => {
    const value = event.target.value;
    setService((prev) => ({ ...prev, [field]: value }));
    setServiceError((prev) => ({ ...prev, [field]: false }));
  };

  const handleChangeActive = (event) => {
    setService((prev) => ({ ...prev, active: event.target.checked }));
  };

  const handleCloseDialog = () => {
    handleClose();
    setServiceError(INIT_ERROR_SERVICE);
    setService({});
  };

  const validateName = () => {
    if (service.name === "") {
      setServiceError((prev) => ({
        ...prev,
        name: "Trường này không được để trống",
      }));
      return false;
    }

    return true;
  };

  const validateIntField = (field) => {
    if (service[field] === "" && !["duration"].includes(field)) {
      setServiceError((prev) => ({
        ...prev,
        [field]: "Trường này không được để trống",
      }));
      return false;
    }

    const intValue = parseInt(service[field], 10);
    if (
      (["price", "discount"].includes(field) && intValue < 0) ||
      (["numberOfSessions", "duration"].includes(field) &&
        service[field] &&
        intValue < 1) ||
      (["discount"].includes(field) && intValue > parseInt(service["price"]))
    ) {
      setServiceError((prev) => ({
        ...prev,
        [field]: "Giá trị không hợp lệ",
      }));
      return false;
    }

    setServiceError((prev) => ({
      ...prev,
      [field]: "",
    }));

    return true;
  };

  const updateRedux = async () => {
    try {
      const res = await apis.service.getServices({ active: true });
      dispatch(setServices(res.services));
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const validate = () =>
    validateName() &&
    validateIntField("price") &&
    validateIntField("discount") &&
    validateIntField("numberOfSessions") &&
    validateIntField("duration");

  const handleCreateService = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.service.createService(service);
      handleCloseDialog();
      toast.success("Thêm dịch vụ mới thành công");
      handleReload();
      updateRedux();
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const handleUpdateService = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const res = await apis.service.updateService(service.id, service);
      if (res.status === 1) {
        handleCloseDialog();
        toast.success("Cập nhật thông tin thành công");
        handleReload();
        updateRedux();
      } else {
        toast.error("Cập nhật thông tin thất bại");
      }
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  return (
    <Dialog
      title={
        dialogType === "create" ? "Thêm dịch vụ" : "Cập nhật thông tin dịch vụ"
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {service && Object.keys(service).length > 0 && (
          <Box>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500} mb={1}>
                  Tên dịch vụ <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <TextField
                  error={!!serviceError.name}
                  helperText={serviceError.name}
                  value={service.name}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeService(event, "name")}
                  onBlur={validateName}
                  placeholder="Tên dịch vụ"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Số buổi <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <TextField
                  error={!!serviceError.numberOfSessions}
                  helperText={serviceError.numberOfSessions}
                  value={service.numberOfSessions}
                  size="small"
                  fullWidth
                  onChange={(event) =>
                    handleChangeService(event, "numberOfSessions")
                  }
                  onBlur={() => validateIntField("numberOfSessions")}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Thời gian / buổi (phút):
                </Typography>
                <TextField
                  error={!!serviceError.duration}
                  helperText={serviceError.duration}
                  value={service.duration || ""}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeService(event, "duration")}
                  onBlur={() => validateIntField("duration")}
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Giá niêm yết <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <CurrencyInput
                  value={service.price}
                  onChange={(event) => handleChangeService(event, "price")}
                  onBlur={() => {
                    validateIntField("price");
                    validateIntField("discount");
                  }}
                  error={!!serviceError.price}
                  helperText={serviceError.price}
                />
                {/* <TextField
                  error={!!serviceError.price}
                  helperText={serviceError.price}
                  value={service.price}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeService(event, "price")}
                  onBlur={() => {
                    validateIntField("price");
                    validateIntField("discount");
                  }}
                  type="number"
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Khuyến mãi:
                </Typography>
                <CurrencyInput
                  value={service.discount}
                  onChange={(event) => handleChangeService(event, "discount")}
                  onBlur={() => {
                    validateIntField("discount");
                  }}
                  error={!!serviceError.discount}
                  helperText={serviceError.discount}
                />
                {/* <TextField
                  error={!!serviceError.discount}
                  helperText={serviceError.discount}
                  value={service.discount}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeService(event, "discount")}
                  onBlur={() => validateIntField("discount")}
                  type="number"
                /> */}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500} mb={1}>
                  Mô tả:
                </Typography>
                <TextField
                  error={!!serviceError.desc}
                  helperText={serviceError.desc}
                  value={service.desc || ""}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeService(event, "desc")}
                  multiline={true}
                  rows={2}
                  placeholder="Mô tả"
                />
              </Grid>
            </Grid>
            {dialogType === "update" && (
              <Stack
                mb={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Trạng thái:</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!service.active}
                      onChange={handleChangeActive}
                    />
                  }
                  label={service.active ? "Hoạt động" : "Không hoạt động"}
                />
              </Stack>
            )}
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>
        {dialogType === "create" ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreateService}
          >
            Thêm mới
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdateService}
          >
            Lưu thay đổi
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ServiceDialog;
