import { toast } from "react-toastify";
import { store } from "../stores";
import { logout } from "../stores/authSlice";

export const handleCallApiError = (error) => {
  if (error.response && error.response.data) {
    switch (error.response.status) {
      case 401:
        store.dispatch(logout());
        toast.error("Không xác thực");
        break;
      case 404:
        toast.error("Không thể truy cập");
        break;
      case 400:
        const messages = error.response.data.messages;
        messages.map((message) => toast.error(message));
        break;
      default:
        toast.error("Lỗi hệ thống, hãy thử lại sau");
        break;
    }
  } else {
    toast.error("Lỗi hệ thống, hãy thử lại sau");
  }
};
