import { styled } from "@mui/material/styles";
import { BORDER_RADIUS } from "../../styles/config";
import { Box } from "@mui/material";

export const StyledCustomPermissionTypeTag = styled(Box)`
  background: ${(props) => props.background};
  width: fit-content;
  padding: 3px 10px;
  border-radius: ${BORDER_RADIUS};
  color: ${(props) => props.color};
`;
