import CustomerHistory from "./CustomerHistory";
// import CustomerInfo from "./CustomerInfo";
import CustomerOrder from "./CustomerOrder";

const tabs = [
  // {
  //   name: "Thông tin khách hàng",
  //   value: "1",
  //   component: CustomerInfo,
  // },
  {
    name: "Lịch sử dịch vụ",
    value: "2",
    component: CustomerOrder,
  },
  {
    name: "Sổ y bạ",
    value: "3",
    component: CustomerHistory,
  },
];

export default tabs;
