import React, { useEffect, useState } from "react";

import { Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import debounce from "../../utils/debounce";
import { PAGINATION_LIMIT } from "../../constants";
import { formatMoney } from "../../utils/money";
import ServiceDialog from "./ServiceDialog";

const ServiceList = ({ search, paging, onChangePage, reload }) => {
  const [services, setServices] = useState([]);
  const [service, setService] = useState({});
  const [loading, setLoading] = useState(false);
  const [serviceDialog, setServiceDialog] = useState(false);

  const handleChangePage = (page) => onChangePage({ ...paging, page });

  const handleOpenServiceDialog = (row) => {
    setService(row);
    setServiceDialog(true);
  };

  const handleCloseServiceDialog = () => {
    setServiceDialog(false);
  };

  const fetchServices = async () => {
    setLoading(true);
    try {
      const condition = {
        search,
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.service.getServices(condition);
      setServices(res.services);
      onChangePage({ ...paging, total: res?.total || 0 });
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      field: "name",
      title: "Dịch vụ",
      align: "left",
    },
    {
      field: "numberOfSessions",
      title: "Số buổi",
      align: "left",
    },
    {
      title: "Thời gian",
      align: "left",
      render: (row) => (row.duration ? `${row.duration} phút` : ""),
    },
    {
      title: "Giá niêm yết (VNĐ)",
      align: "left",
      render: (row) => formatMoney(row.price),
    },
    {
      title: "Khuyến mãi (VNĐ)",
      align: "left",
      render: (row) => formatMoney(row.discount),
    },
    {
      field: "desc",
      title: "Mô tả",
      align: "left",
    },
    {
      title: "Trạng thái",
      align: "center",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success }} fontSize="13px">
              Hoạt động
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.danger }} fontSize="13px">
              Không hoạt động
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Tooltip title="Chỉnh sửa">
            <IconButton onClick={() => handleOpenServiceDialog(row)}>
              <Edit sx={{ color: COLOR.purple }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    debounce(fetchServices, 500)(search);
  }, [paging.page, search, reload]);

  return (
    <>
      <Table
        data={services}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <ServiceDialog
        open={serviceDialog}
        handleClose={handleCloseServiceDialog}
        service={service}
        setService={setService}
        handleReload={fetchServices}
        dialogType="update"
      />
    </>
  );
};

export default ServiceList;
