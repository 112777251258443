import {
  Box,
  Divider,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Autorenew } from "@mui/icons-material";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { INITIAL_PAGING, PAGINATION_LIMIT } from "../../constants";
import { formatMoney } from "../../utils/money";
import DatePickerRange from "../../components/PickDateRange";
import BarChart from "../../components/Charts/BarChart";
import Table from "../../components/Table";

const INIT_LOADING = {
  statistic: false,
  detail: false,
};
const INIT_DATE = [dayjs().startOf("month"), dayjs().endOf("month")];

const UserRevenue = ({ user }) => {
  const [loading, setLoading] = useState(INIT_LOADING);
  const [contributionByDay, setContributionByDay] = useState({});
  const [contributionByMonth, setContributionByMonth] = useState({});
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [date, setDate] = useState(INIT_DATE);
  const [contributions, setContributions] = useState([]);

  const fetchContributionByDay = async () => {
    try {
      const res = await apis.statistic.getUserRevenue(user.id, {
        type: "day",
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
      const revenue = res.revenue || {};
      setContributionByDay({
        categories: Object.keys(revenue).map((item) => item.split("-")[0]),
        series: [{ name: "Doanh thu", data: Object.values(revenue) }],
      });
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const fetchContributionByMonth = async () => {
    try {
      const res = await apis.statistic.getUserRevenue(user.id, {
        type: "month",
      });
      const revenue = res.revenue || {};
      setContributionByMonth({
        categories: Object.keys(revenue),
        series: [{ name: "Doanh thu", data: Object.values(revenue) }],
      });
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const fetchStatistics = async () => {
    setLoading((prev) => ({ ...prev, statistic: true }));
    await Promise.all([fetchContributionByDay(), fetchContributionByMonth()]);
    setLoading((prev) => ({ ...prev, statistic: false }));
  };

  const fetchContributions = async () => {
    setLoading((prev) => ({ ...prev, detail: true }));
    try {
      const [startDate, endDate] = date;
      const condition = {
        userId: user.id,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.contribution.getContributions(condition);
      setContributions(res.contributions);
      setPaging((prev) => ({ ...prev, total: res.total || 0 }));
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading((prev) => ({ ...prev, detail: false }));
  };

  const handleRefresh = () => {
    setDate(INIT_DATE);
    setPaging(INITIAL_PAGING);
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
  };

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    handleChangePage(INITIAL_PAGING.page);
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  useEffect(() => {
    fetchContributions();
  }, [paging.page, date]);

  const columns = [
    {
      field: "customerName",
      title: "Khách hàng",
      align: "left",
    },
    {
      field: "serviceName",
      title: "Dịch vụ",
      align: "left",
    },
    {
      title: "Số tiền (VNĐ)",
      align: "left",
      render: (row) => formatMoney(row.value),
    },
    {
      field: "creatorName",
      title: "Người tạo",
      align: "left",
    },
    {
      title: "Ngày tạo",
      align: "left",
      render: (row) => dayjs(row.createdAt).format("HH:mm DD-MM-YYYY"),
    },
  ];

  return (
    <Box>
      <Typography variant="h6" mb={1}>
        Tổng quan
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {loading.statistic ? (
            <>
              <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
              <Skeleton variant="rectangular" sx={{ height: "400px" }} />
            </>
          ) : (
            <BarChart
              title={`Doanh thu theo ngày trong tháng ${dayjs().format(
                "MM-YYYY"
              )}`}
              categories={contributionByDay.categories}
              series={contributionByDay.series}
              height="400px"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading.statistic ? (
            <>
              <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
              <Skeleton variant="rectangular" sx={{ height: "400px" }} />
            </>
          ) : (
            <BarChart
              title="Doanh thu 6 tháng gần nhất"
              categories={contributionByMonth.categories}
              series={contributionByMonth.series}
              height="400px"
            />
          )}
        </Grid>
      </Grid>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        my={2}
      >
        <Typography variant="h6">Danh sách chốt thẻ</Typography>
        <Box display="flex">
          <DatePickerRange value={date} onChange={handleChangeDate} />
          <Tooltip title="Đặt lại">
            <IconButton aria-label="refresh" onClick={handleRefresh}>
              <Autorenew />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
      <Table
        data={contributions}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading.detail}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </Box>
  );
};

export default UserRevenue;
