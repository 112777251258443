import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const serviceSlice = createSlice({
  name: "service",
  initialState: initialState,
  reducers: {
    setServices: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setServices } = serviceSlice.actions;

export default serviceSlice.reducer;
