import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Autocomplete,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";
import { COLOR } from "../../styles/color";

import {
  validateRequired as checkRequired,
  validateInt as checkInt,
} from "../../utils/validate";

const INIT_HISTORY_ERROR = {
  userId: "",
  serviceDuration: "",
};

const HistoryDialog = ({
  open,
  handleClose,
  orderItem,
  history,
  setHistory,
  reload,
  dialogType,
}) => {
  const [loading, setLoading] = useState(false);
  const [handling, setHandling] = useState(false);
  const [users, setUsers] = useState([]);
  const [historyError, setHistoryError] = useState(INIT_HISTORY_ERROR);

  const handleChangeHistory = (newValue, field) => {
    setHistory((prev) => ({ ...prev, [field]: newValue }));
    if (Object.keys(INIT_HISTORY_ERROR).includes(field))
      setHistoryError((prev) => ({ ...prev, [field]: "" }));
  };

  const handleCloseDialog = () => {
    handleClose();
    setHistoryError(INIT_HISTORY_ERROR);
  };

  const validateRequired = (field) => {
    if (!checkRequired(history[field])) {
      setHistoryError((prev) => ({
        ...prev,
        [field]: "Trường này không được để trống",
      }));
      return false;
    }
    return true;
  };

  const validateServiceDuration = () => {
    if (!checkRequired(history.serviceDuration)) {
      setHistoryError((prev) => ({
        ...prev,
        serviceDuration: "Trường này không được để trống",
      }));
      return false;
    }

    if (history.serviceDuration && !checkInt(history.serviceDuration, 10)) {
      setHistoryError((prev) => ({
        ...prev,
        serviceDuration: "Thời lượng không hợp lệ",
      }));
      return false;
    }

    return true;
  };

  const validate = () =>
    validateRequired("userId") && validateServiceDuration();

  const getHistoryData = () => {
    const user = users.find((user) => user.id === history.userId);
    const data = {
      ...history,
      userName: user ? `${user.name} - ${user.phoneNumber}` : "",
      serviceId: orderItem.serviceId,
      serviceName: orderItem.name,
      orderId: orderItem.orderId,
      orderItemId: orderItem.id,
    };

    if (dialogType === "create")
      data.session = (orderItem.usageHistories?.length || 0) + 1;

    return data;
  };

  const handleCreateHistory = async () => {
    if (!validate()) return;
    setHandling(true);
    try {
      await apis.history.createHistory(getHistoryData());
      handleCloseDialog();
      reload();
      toast.success("Thêm buổi sử dụng thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setHandling(false);
  };

  const handleUpdateHistory = async () => {
    if (!validate()) return;
    setHandling(true);
    try {
      await apis.history.updateHistory(history.id, getHistoryData());
      handleCloseDialog();
      reload();
      toast.success("Cập nhật buổi sử dụng");
    } catch (error) {
      handleCallApiError(error);
    }
    setHandling(false);
  };

  const renderSubTitle = () => {
    const sessions =
      dialogType === "update"
        ? history.index
        : orderItem.usageHistories?.length || 0;

    return (
      <>
        <span>
          [{" "}
          <strong>Buổi {sessions < 10 ? `0${sessions + 1}` : sessions}</strong>{" "}
          ] - {orderItem.name}
        </span>
      </>
    );
  };

  const renderUserName = (userId) => {
    const user = users.find((u) => u.id === userId);
    if (!user) return "";
    return `${user.name} - ${user.phoneNumber}`;
  };

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const res = await apis.user.getUsers({ active: true });
      setUsers(res.users);
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) fetchUsers();
  }, [open]);

  return (
    <Dialog
      title={
        dialogType === "create" ? "Thêm buổi sử dụng" : "Cập nhận buổi sử dụng"
      }
      subTitle={renderSubTitle()}
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12} sm={7}>
              <Typography fontWeight={500} mb={1}>
                Kỹ thuật viên phụ trách{" "}
                <span style={{ color: COLOR.error }}>*</span>:
              </Typography>
              <Autocomplete
                size="small"
                value={history.userId}
                options={users.map((user) => user.id)}
                getOptionLabel={(option) => renderUserName(option)}
                filterSelectedOptions
                onChange={(event, newValue) =>
                  handleChangeHistory(newValue, "userId")
                }
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {renderUserName(key)}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!historyError.userId}
                    helperText={historyError.userId}
                  />
                )}
                onBlur={() => validateRequired("userId")}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography fontWeight={500} mb={1}>
                Thời lượng thực hiện{" "}
                <span style={{ color: COLOR.error }}>*</span>:
              </Typography>
              <TextField
                error={!!historyError.serviceDuration}
                helperText={historyError.serviceDuration}
                value={history.serviceDuration}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleChangeHistory(event.target.value, "serviceDuration")
                }
                onBlur={validateServiceDuration}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                Tình trạng của khách hàng:
              </Typography>
              <TextField
                value={history.status || ""}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleChangeHistory(event.target.value, "status")
                }
                multiline={true}
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                Đánh giá của khách hàng:
              </Typography>
              <TextField
                value={history.feedback || ""}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleChangeHistory(event.target.value, "feedback")
                }
                multiline={true}
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography fontWeight={500} mb={1}>
                Ghi chú:
              </Typography>
              <TextField
                value={history.note || ""}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleChangeHistory(event.target.value, "note")
                }
                multiline={true}
                rows={2}
              />
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>
        {dialogType === "create" ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={handling}
            onClick={handleCreateHistory}
          >
            Thêm mới
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={handling}
            onClick={handleUpdateHistory}
          >
            Lưu thay đổi
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default HistoryDialog;
