const A_WEEK = 7 * 24 * 60 * 60 * 1000;
const PAGINATION_LIMIT = 10;
const EXPORT_LIMIT = 100;
const INITIAL_PAGING = { page: 1, total: 0 };
const ALL = "all";

const GENDER = {
  MALE: "Nam",
  FEMALE: "Nữ",
  OTHER: "Khác",
};

export { A_WEEK, PAGINATION_LIMIT, EXPORT_LIMIT, INITIAL_PAGING, GENDER, ALL };
