import React, { useEffect, useState } from "react";
import { AddCircleOutline, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import NoData from "../../components/NoData";
import { StyledTable } from "../../components/Table/index.style";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";
import HistoryDialog from "./HistoryDialog";
import UpdateOrderItemDialog from "./UpdateOrderItemDialog";
import DeleteHistoryDialog from "./DeleteHistoryDialog";

const INIT_HISTORY = {
  userId: null,
  serviceDuration: 30,
  status: "",
  feedback: "",
  note: "",
};

const Row = ({
  row,
  index,
  handleOpenHistoryDialog,
  handleOpenUpdateOrderItemDialog,
  handleOpenDeleteHistoryDialog,
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        hover
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: "pointer",
        }}
        className="body-row"
        onClick={() => setOpen(!open)}
      >
        <TableCell className="body-cell-collapse" align="center">
          {index + 1}
        </TableCell>
        <TableCell className="body-cell-collapse" component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {row.duration ? `${row.duration} phút` : ""}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {`${row.usageHistories.length} / ${row.numberOfSessions}`} buổi
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {row.note}
        </TableCell>
        <TableCell className="body-cell-collapse">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title="Thêm ghi chú">
              <IconButton
                onClick={(e) => handleOpenUpdateOrderItemDialog(row, e)}
              >
                <Edit sx={{ color: COLOR.purple }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Thêm buổi sử dụng">
              <span>
                <IconButton
                  onClick={(e) => handleOpenHistoryDialog("create", row, {}, e)}
                  disabled={row.usageHistories.length >= row.numberOfSessions}
                >
                  <AddCircleOutline
                    color={
                      row.usageHistories.length >= row.numberOfSessions
                        ? ""
                        : "error"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: TRANSPARENT_COLOR.light,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.usageHistories.length ? (
              <Box my={1}>
                <Typography fontWeight={500}>Chi tiết</Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Buổi</TableCell>
                      <TableCell align="left">Kỹ thuật viên</TableCell>
                      <TableCell align="left">Thời lượng</TableCell>
                      <TableCell align="left">Trạng thái</TableCell>
                      <TableCell align="left">Đánh giá</TableCell>
                      <TableCell align="left">Ghi chú</TableCell>
                      <TableCell align="center">...</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.usageHistories.map((history, index) => (
                      <TableRow hover key={index}>
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{history.userName}</TableCell>
                        <TableCell align="left">
                          {history.serviceDuration} phút
                        </TableCell>
                        <TableCell align="left">{history.status}</TableCell>
                        <TableCell align="left">{history.feedback}</TableCell>
                        <TableCell align="left">{history.note}</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Tooltip title="Cập nhật buổi sử dụng">
                              <IconButton
                                onClick={() =>
                                  handleOpenHistoryDialog("update", row, {
                                    ...history,
                                    index,
                                  })
                                }
                              >
                                <Edit sx={{ color: COLOR.purple }} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Xóa buổi sử dụng">
                              <span>
                                <IconButton
                                  onClick={() =>
                                    handleOpenDeleteHistoryDialog(row, {
                                      ...history,
                                      index,
                                    })
                                  }
                                  disabled={
                                    row.usageHistories.length - 1 !== index
                                  }
                                >
                                  <Delete
                                    color={
                                      row.usageHistories.length - 1 !== index
                                        ? ""
                                        : "error"
                                    }
                                  />
                                </IconButton>
                              </span>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <NoData />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CustomerHistory = ({ customer }) => {
  const [loading, setLoading] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [orderItem, setOrderItem] = useState({});
  const [dialogType, setDialogType] = useState("");
  const [history, setHistory] = useState(INIT_HISTORY);
  const [historyDialog, setHistoryDialog] = useState(false);
  const [updateOrderItemDialog, setUpdateOrderItemDialog] = useState(false);
  const [deleteHistoryDialog, setDeleteHistoryDialog] = useState(false);

  const handleOpenHistoryDialog = (type, orderItem, row, e) => {
    if (type === "update") setHistory(row);
    if (type === "create" && orderItem.duration) {
      e.stopPropagation();
      setHistory({
        ...INIT_HISTORY,
        serviceDuration: orderItem.duration,
      });
    }

    setOrderItem(orderItem);
    setDialogType(type);
    setHistoryDialog(true);
  };

  const handleCloseHistoryDialog = () => {
    setHistoryDialog(false);
    setOrderItem({});
    setHistory(INIT_HISTORY);
  };

  const handleOpenUpdateOrderItemDialog = (row, e) => {
    e.stopPropagation();
    setOrderItem(row);
    setUpdateOrderItemDialog(true);
  };

  const handleCloseUpdateOrderItemDialog = (row) => {
    setUpdateOrderItemDialog(false);
    setOrderItem({});
  };

  const handleOpenDeleteHistoryDialog = (orderItem, row) => {
    setHistory(row);
    setOrderItem(orderItem);
    setDeleteHistoryDialog(true);
  };

  const handleCloseDeleteHistoryDialog = () => {
    setDeleteHistoryDialog(false);
    setHistory(INIT_HISTORY);
    setOrderItem({});
  };

  const fetchOrderItems = async (displayLoading = false) => {
    if (displayLoading) setLoading(true);
    try {
      const condition = {
        customerId: customer.id,
      };
      const res = await apis.orderItem.getOrderItems(condition);
      setOrderItems(res.orderItems);
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOrderItems(true);
  }, []);

  return (
    <>
      <StyledTable component={Box}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">STT</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Dịch vụ</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Thời lượng</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Đã sử dụng</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Ghi chú</Typography>
              </TableCell>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">...</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="body-row">
                <TableCell colSpan={7}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              orderItems.map((row, index) => (
                <Row
                  key={row.id}
                  row={row}
                  index={index}
                  handleOpenHistoryDialog={handleOpenHistoryDialog}
                  handleOpenUpdateOrderItemDialog={
                    handleOpenUpdateOrderItemDialog
                  }
                  handleOpenDeleteHistoryDialog={handleOpenDeleteHistoryDialog}
                />
              ))
            )}
          </TableBody>
        </Table>
        {!loading && !orderItems.length && <NoData />}
      </StyledTable>
      <HistoryDialog
        open={historyDialog}
        handleClose={handleCloseHistoryDialog}
        orderItem={orderItem}
        history={history}
        setHistory={setHistory}
        reload={fetchOrderItems}
        dialogType={dialogType}
      />
      <UpdateOrderItemDialog
        open={updateOrderItemDialog}
        handleClose={handleCloseUpdateOrderItemDialog}
        orderItem={orderItem}
        reload={fetchOrderItems}
      />
      <DeleteHistoryDialog
        open={deleteHistoryDialog}
        handleClose={handleCloseDeleteHistoryDialog}
        orderItem={orderItem}
        history={history}
        reload={fetchOrderItems}
      />
    </>
  );
};

export default CustomerHistory;
