import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentRole: null,
  isMasterRole: false,
  permissions: [],
};

export const roleSlice = createSlice({
  name: "role",
  initialState: initialState,
  reducers: {
    setCurrentRole: (state, action) => {
      return { ...state, currentRole: action.payload };
    },
    setIsMasterRole: (state, action) => {
      return { ...state, isMasterRole: action.payload };
    },
    setPermissions: (state, action) => {
      return { ...state, permissions: [...action.payload] };
    },
  },
});

export const { setCurrentRole, setIsMasterRole, setPermissions } =
  roleSlice.actions;

export default roleSlice.reducer;
