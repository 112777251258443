import { styled } from "@mui/material/styles";
import { TableContainer, TableCell, Box } from "@mui/material";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";

const StyledTable = styled(TableContainer)`
  background: ${COLOR.white};
  .header-cell {
    padding: 6px 12px;
  }

  .header-item {
    display: flex;
    align-items: center;
  }

  .header-left {
    justify-content: left;
  }

  .header-right {
    justify-content: right;
  }

  .header-center {
    justify-content: center;
  }

  .header-title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
  }

  .header-icon,
  .header-checkbox {
    color: ${COLOR.white} !important;
  }

  .body-row {
    &:last-child td,
    &:last-child th {
      border: 0;
    }
    &:hover {
      background: ${TRANSPARENT_COLOR.light};
    }
  }

  .body-cell {
    border-bottom: 1px solid ${COLOR.divider};
    padding: 16px 12px;
    width: ${(props) => (props.width ? props.width : "auto")};
    font-size: 13px;
  }

  .body-cell-collapse {
    padding: 16px 12px;
    width: ${(props) => (props.width ? props.width : "auto")};
    font-size: 13px;
  }
`;

const StyledTableCell = styled(TableCell)`
  width: ${(props) => (props.width ? props.width : "auto")};
`;

const StyledTableFooter = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  padding: 20px;

  .Mui-selected {
    color: ${COLOR.white} !important;
  }
  .footer-label {
    font-size: 12px;
    font-weight: 600;
  }
`;

export { StyledTable, StyledTableCell, StyledTableFooter };
