import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLOR } from "../../styles/color";

export const StyledCurrencyInput = styled(Box)`
  .currency-input {
    width: 100%;
    padding: 8.5px 14px;
    border-radius: 8px;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    border: 1px solid
      ${(props) => (props.error ? COLOR.helperText : COLOR.light)};
    line-height: 1.3235;
  }

  .currency-input:hover {
    border: 1px solid ${COLOR.black};
  }

  .currency-input:focus {
    outline: 2px solid ${COLOR.indicator};
    border: none;
  }

  .helper-text {
    margin: 4px 14px 0 14px;
    letter-spacing: 1/30em;
    text-align: left;
    line-height: 1.66;
    font-weight: 400;
    font-size: 0.74em;
    color: ${COLOR.helperText};
  }
`;
