import CurrencyInputBase from "react-currency-input-field";
import { StyledCurrencyInput } from "./index.style";
import { Typography } from "@mui/material";

const CurrencyInput = ({ value, onChange, onBlur, error, helperText }) => {
  const handleOnChange = (newValue) => {
    const event = { target: { value: newValue || "" } };
    onChange(event);
  };

  return (
    <StyledCurrencyInput error={error}>
      <CurrencyInputBase
        id="input-example"
        name="input-name"
        value={value}
        decimalsLimit={false}
        onValueChange={(newValue) => {
          handleOnChange(newValue);
        }}
        className="currency-input"
        onBlur={onBlur}
      />
      {error && <Typography className="helper-text">{helperText}</Typography>}
    </StyledCurrencyInput>
  );
};

export default CurrencyInput;
