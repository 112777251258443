import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";

import { AddCircleOutline, Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import debounce from "../../utils/debounce";
import { GENDER, PAGINATION_LIMIT } from "../../constants";
import CustomerDialog from "./CustomerDialog";
import { INIT_ORDER } from "../../constants/order";
import OrderDialog from "../../components/OrderDialog";

const CustomerList = ({ search, paging, onChangePage, reload }) => {
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState({});
  const [loading, setLoading] = useState(false);
  const [customerDialog, setCustomerDialog] = useState(false);
  const [order, setOrder] = useState(INIT_ORDER);
  const [orderDialog, setOrderDialog] = useState(false);

  const handleChangePage = (page) => onChangePage({ ...paging, page });

  const handleOpenCustomerDialog = (row) => {
    setCustomer({
      ...row,
      dateOfBirth: row.dateOfBirth
        ? dayjs(row.dateOfBirth, "DD/MM/YYYY")
        : null,
    });
    setCustomerDialog(true);
  };

  const handleCloseCustomerDialog = () => {
    setCustomerDialog(false);
  };

  const handleOpenOrderDialog = (row) => {
    setCustomer(row);
    setOrderDialog(true);
  };

  const handleCloseOrderDialog = () => {
    setOrderDialog(false);
    setCustomer({});
    setOrder(INIT_ORDER);
  };

  const fetchCustomers = async () => {
    setLoading(true);
    try {
      const condition = {
        search,
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.customer.getCustomers(condition);
      setCustomers(res.customers);
      onChangePage({ ...paging, total: res?.total || 0 });
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const redirectCustomerDetailPage = (customerId) =>
    history.push(`/customer/${customerId}`);

  const columns = [
    {
      title: "Họ và tên",
      align: "left",
      render: (row) => (
        <Typography
          sx={{ cursor: "pointer" }}
          color="primary"
          fontSize="13px"
          onClick={() => redirectCustomerDetailPage(row.id)}
        >
          {row.name}
        </Typography>
      ),
    },
    {
      field: "phoneNumber",
      title: "SĐT",
      align: "left",
    },
    {
      title: "Giới tính",
      align: "left",
      render: (row) => GENDER[row.gender.toUpperCase()],
    },

    {
      field: "dateOfBirth",
      title: "Ngày sinh",
      align: "left",
    },
    {
      field: "address",
      title: "Địa chỉ",
      align: "left",
    },
    {
      field: "note",
      title: "Ghi chú",
      align: "left",
    },
    {
      title: "Trạng thái",
      align: "center",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success }} fontSize="13px">
              Hoạt động
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.danger }} fontSize="13px">
              Không hoạt động
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Tooltip title="Đăng ký dịch vụ">
            <IconButton onClick={() => handleOpenOrderDialog(row)}>
              <AddCircleOutline sx={{ color: COLOR.error }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Chỉnh sửa">
            <IconButton onClick={() => handleOpenCustomerDialog(row)}>
              <Edit sx={{ color: COLOR.purple }} />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    debounce(fetchCustomers, 500)(search);
  }, [paging.page, search, reload]);

  return (
    <>
      <Table
        data={customers}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <CustomerDialog
        open={customerDialog}
        handleClose={handleCloseCustomerDialog}
        customer={customer}
        setCustomer={setCustomer}
        handleReload={fetchCustomers}
        dialogType="update"
      />
      <OrderDialog
        open={orderDialog}
        handleClose={handleCloseOrderDialog}
        customer={customer}
        order={order}
        setOrder={setOrder}
      />
    </>
  );
};

export default CustomerList;
