import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";
import { toast } from "react-toastify";

const DeleteHistoryDialog = ({
  open,
  handleClose,
  orderItem,
  history,
  reload,
}) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteHistory = async () => {
    setLoading(true);
    try {
      await apis.history.deleteHistory(history.id);
      handleClose();
      reload();
      toast.success("Xóa buổi sử dụng thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const renderSubTitle = () => {
    const sessions = history.index || 0;

    return (
      <>
        <span>
          [{" "}
          <strong>Buổi {sessions < 10 ? `0${sessions + 1}` : sessions}</strong>{" "}
          ] - {orderItem.name}
        </span>
      </>
    );
  };
  return (
    <Dialog
      title="Xóa buổi sử dụng"
      subTitle={renderSubTitle()}
      maxWidth="sm"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Typography textAlign="center" fontStyle="italic">
          <span style={{ fontWeight: 600 }}>Lưu ý:</span> Hành động này không
          thể hoàn tác !
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Hủy
        </Button>

        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={handleDeleteHistory}
        >
          Xác nhận xóa
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteHistoryDialog;
