import { Autorenew, Description, Search } from "@mui/icons-material";
import dayjs from "dayjs";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { EXPORT_LIMIT, INITIAL_PAGING } from "../../constants";
import DatePickerRange from "../../components/PickDateRange";
import { LoadingButton } from "@mui/lab";
import ContributionList from "./ContributionList";
import { exportExcel } from "../../utils/excel";

const INIT_DATE = [dayjs().startOf("month"), dayjs().endOf("month")];

const Contribution = () => {
  const [search, setSearch] = useState("");
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [date, setDate] = useState(INIT_DATE);
  const [userId, setUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);

  const handleChangePaging = ({ page, total }) => {
    setPaging({ page: page ?? 1, total: total ?? 0 });
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleChangeDate = (newDate) => {
    setDate(newDate);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleChangeUserId = (newValue) => {
    setUserId(newValue);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleRefresh = () => {
    setDate(INIT_DATE);
    setPaging(INITIAL_PAGING);
  };

  const heads = {
    no: "STT",
    customerName: "Khách hàng",
    serviceName: "Dịch vụ",
    value: "Tổng số tiền",
    userName: "Người chốt thẻ",
    createdAt: "Thời gian tạo",
    creatorName: "Người tạo",
  };

  const handleExportExcel = async () => {
    try {
      setExporting(true);
      const [startDate, endDate] = date;
      const condition = {
        search,
        userId,
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
        limit: EXPORT_LIMIT,
      };

      const total = paging.total;
      const turns = Math.ceil(total / EXPORT_LIMIT);

      let readableData = [];
      for (let page = 0; page < turns; page++) {
        condition.offset = page * EXPORT_LIMIT;
        const res = await apis.contribution.getContributions(condition);

        if (res.contributions.length === 0) {
          setLoading(false);
          toast.warn("Không có hoạt động chốt thẻ nào");
          return;
        }

        readableData = readableData.concat(
          res.contributions.map((row, index) => ({
            no: page * EXPORT_LIMIT + index + 1,
            customerName: row.customerName,
            serviceName: row.serviceName,
            value: row.value,
            userName: row.userName,
            createdAt: dayjs(row.createdAt).format("HH:mm DD/MM/YYYY"),
            creatorName: row.creatorName,
          }))
        );
      }

      const csvData = readableData.map((data) => {
        const mappingData = {};
        Object.keys(heads).forEach(
          (key) => (mappingData[heads[key]] = data[key])
        );
        return mappingData;
      });
      const fileName = `Danh_sach_chot_the.xlsx`;

      exportExcel(csvData, fileName, Object.values(heads));
    } catch (error) {
      handleCallApiError(error);
    }
    setExporting(false);
  };

  const fetchUsers = async () => {
    try {
      const res = await apis.user.getUsers({ active: true });
      setUsers(res.users);
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const renderUserName = (userId) => {
    const user = users.find((u) => u.id === userId);
    if (!user) return "";
    return `${user.name} - ${user.phoneNumber}`;
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Box padding={2}>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={7} md={7} lg={3}>
          <TextField
            value={search}
            size="small"
            fullWidth
            placeholder={"Tìm kiếm theo khách hàng, dịch vụ"}
            onChange={handleChangeSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={2}>
          <Autocomplete
            size="small"
            value={userId}
            options={users.map((user) => user.id)}
            getOptionLabel={(option) => renderUserName(option)}
            filterSelectedOptions
            onChange={(event, newValue) => handleChangeUserId(newValue)}
            renderOption={(props, key) => (
              <MenuItem value={key} {...props}>
                {renderUserName(key)}
              </MenuItem>
            )}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={5}>
          <Box display="flex">
            <DatePickerRange value={date} onChange={handleChangeDate} />
            <Tooltip title="Đặt lại">
              <IconButton aria-label="refresh" onClick={handleRefresh}>
                <Autorenew />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <LoadingButton
              color="success"
              variant="outlined"
              startIcon={<Description />}
              loading={exporting}
              disabled={loading || !paging.total}
              onClick={handleExportExcel}
            >
              Xuất Excel
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
      <ContributionList
        search={search}
        userId={userId}
        date={date}
        paging={paging}
        onChangePage={handleChangePaging}
        loading={loading}
        setLoading={setLoading}
      />
    </Box>
  );
};

export default Contribution;
