import { Box } from "@mui/material";
import Overview from "./Overview";
import Revenue from "./Revenue";

const Dashboard = () => {
  return (
    <Box paddingX={2} paddingTop={2}>
      <Overview />
      <Revenue />
    </Box>
  );
};

export default Dashboard;
