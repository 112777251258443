import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Edit, AddCardRounded, PersonAdd, Add } from "@mui/icons-material";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { ALL, INITIAL_PAGING, PAGINATION_LIMIT } from "../../constants";
import {
  INIT_ORDER,
  ORDER_STATUS,
  ORDER_STATUS_LABEL,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
  PAYMENT_TYPE,
  PAYMENT_TYPE_SHORT_LABEL,
} from "../../constants/order";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";
import { formatMoney } from "../../utils/money";
import OrderDialog from "../../components/OrderDialog";
import UpdateOrderDialog from "./UpdateOrderDialog";
import ContributorDialog from "./ContributorDialog";
import { StyledTable } from "../../components/Table/index.style";
import NoData from "../../components/NoData";
import CustomTableFooter from "../../components/Table/TableFooter";
import CreateTransactionDialog from "./CreateTransactionDialog";
import UpdateTransactionDialog from "./UpdateTransactionDialog";

const INIT_TRANSACTION = {
  amountOfMoney: 0,
  method: PAYMENT_METHOD.TRANSFER,
  note: "",
};

const Row = ({
  row,
  index,
  handleOpenUpdateOrderDialog,
  handleOpenContributorDialog,
  handleOpenCreateTransactionDialog,
  handleOpenUpdateTransactionDialog,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    if (row.status === ORDER_STATUS.CANCELLED) {
      setOpen(false);
      return;
    }
    setOpen(!open);
  };

  const renderItemsName = (orderItems) => {
    return (
      <>
        {orderItems.map((orderItem) => (
          <Typography fontSize="13px" key={orderItem.id}>
            {orderItem.name}
          </Typography>
        ))}
      </>
    );
  };

  const renderPaymentType = (row) => {
    return (
      <>
        <Typography fontSize="13px">
          {PAYMENT_TYPE_SHORT_LABEL[row.paymentType]}
        </Typography>
        {row.status === ORDER_STATUS.INCOMPLETE &&
          row.paymentType === PAYMENT_TYPE.PARTIAL_PAYMENT && (
            <Typography fontSize="13px">
              (
              {formatMoney(
                row?.transactions?.reduce(
                  (acc, transaction) => acc + transaction.amountOfMoney,
                  0
                ) || 0
              )}
              )
            </Typography>
          )}
      </>
    );
  };

  const renderOrderStatus = (status) => {
    switch (status) {
      case ORDER_STATUS.DONE:
        return (
          <Typography sx={{ color: COLOR.success }} fontSize="13px">
            {ORDER_STATUS_LABEL[status]}
          </Typography>
        );

      case ORDER_STATUS.INCOMPLETE:
        return (
          <Typography sx={{ color: COLOR.error }} fontSize="13px">
            {ORDER_STATUS_LABEL[status]}
          </Typography>
        );

      case ORDER_STATUS.CANCELLED:
        return (
          <Typography sx={{ color: COLOR.dark }} fontSize="13px">
            {ORDER_STATUS_LABEL[status]}
          </Typography>
        );

      default:
        return <>status</>;
    }
  };

  return (
    <React.Fragment>
      <TableRow
        hover
        sx={{
          "& > *": { borderBottom: "unset" },
          cursor: [ORDER_STATUS.DONE, ORDER_STATUS.INCOMPLETE].includes(
            row.status
          )
            ? "pointer"
            : "",
        }}
        className="body-row"
        onClick={handleOpen}
      >
        <TableCell className="body-cell-collapse" align="center">
          {index + 1}
        </TableCell>
        <TableCell className="body-cell-collapse" component="th" scope="row">
          {renderItemsName(row.orderItems)}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {formatMoney(row.total)}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {renderPaymentType(row)}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {dayjs(row.createdAt).format("HH:mm DD/MM/YYYY")}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {row.note}
        </TableCell>
        <TableCell className="body-cell-collapse" align="left">
          {renderOrderStatus(row.status)}
        </TableCell>
        <TableCell className="body-cell-collapse">
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title="Cập nhật thông tin">
              <IconButton onClick={(e) => handleOpenUpdateOrderDialog(row, e)}>
                <Edit sx={{ color: COLOR.purple }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cập nhật người chốt thẻ">
              <span>
                <IconButton
                  onClick={(e) => handleOpenContributorDialog(row, e)}
                  disabled={row.status === ORDER_STATUS.CANCELLED}
                >
                  <PersonAdd
                    sx={{
                      color:
                        row.status === ORDER_STATUS.CANCELLED
                          ? ""
                          : COLOR.orange,
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Thanh toán bổ sung">
              <span>
                <IconButton
                  onClick={(e) => handleOpenCreateTransactionDialog(row, e)}
                  disabled={[
                    ORDER_STATUS.DONE,
                    ORDER_STATUS.CANCELLED,
                  ].includes(row.status)}
                >
                  <AddCardRounded
                    color={
                      [ORDER_STATUS.DONE, ORDER_STATUS.CANCELLED].includes(
                        row.status
                      )
                        ? ""
                        : "error"
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: TRANSPARENT_COLOR.light,
          }}
          colSpan={8}
        >
          <Collapse
            in={
              [ORDER_STATUS.DONE, ORDER_STATUS.INCOMPLETE].includes(
                row.status
              ) && open
            }
            timeout="auto"
            unmountOnExit
          >
            <Box my={1}>
              <Typography fontWeight={500}>Chi tiết thanh toán</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Lượt</TableCell>
                    <TableCell align="left">Số tiền</TableCell>
                    <TableCell align="left">Phương thức</TableCell>
                    <TableCell align="left">Người tạo</TableCell>
                    <TableCell align="left">Ghi chú</TableCell>
                    <TableCell align="center">Trạng thái</TableCell>
                    <TableCell align="center">...</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.transactions.map((transaction, index) => (
                    <TableRow hover key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        {formatMoney(transaction.amountOfMoney)}
                      </TableCell>
                      <TableCell align="left">
                        {PAYMENT_METHOD_LABEL[transaction.method]}
                      </TableCell>
                      <TableCell align="left">
                        {transaction.creatorName}
                      </TableCell>
                      <TableCell align="left">{transaction.note}</TableCell>
                      <TableCell align="center">
                        {renderOrderStatus(transaction.status)}
                      </TableCell>
                      <TableCell>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Tooltip title="Cập nhật thanh toán">
                            <span>
                              <IconButton
                                onClick={() =>
                                  handleOpenUpdateTransactionDialog(row, {
                                    ...transaction,
                                    index,
                                  })
                                }
                                disabled={!!transaction.isFirst}
                              >
                                <Edit
                                  sx={{
                                    color: transaction.isFirst
                                      ? ""
                                      : COLOR.purple,
                                  }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CustomerOrder = ({ customer }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(ALL);
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [order, setOrder] = useState(INIT_ORDER);
  const [orderDialog, setOrderDialog] = useState(false);
  const [updateOrderDialog, setUpdateOrderDialog] = useState(false);
  const [contributorDialog, setContributorDialog] = useState(false);
  const [transaction, setTransaction] = useState(INIT_TRANSACTION);
  const [createTransactionDialog, setCreateTransactionDialog] = useState(false);
  const [updateTransactionDialog, setUpdateTransactionDialog] = useState(false);

  const fetchOrders = async (displayLoading = false) => {
    if (displayLoading) setLoading(true);
    try {
      const condition = {
        customerId: customer.id,
        status: status !== ALL ? status : null,
        limit: PAGINATION_LIMIT,
        offset: (paging.page - 1) * PAGINATION_LIMIT,
      };
      const res = await apis.order.getOrders(condition);
      setOrders(res.orders);
      setPaging((prev) => ({ ...prev, total: res.total || 0 }));
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
  };

  const handleChangeStatus = (event) => {
    const { value } = event.target;
    setStatus(value);
    handleChangePage(INITIAL_PAGING.page);
  };

  const handleOpenOrderDialog = () => {
    setOrderDialog(true);
  };

  const handleCloseOrderDialog = () => {
    setOrderDialog(false);
    setOrder(INIT_ORDER);
  };

  const handleOpenUpdateOrderDialog = (row, e) => {
    e.stopPropagation();
    setOrder(row);
    setUpdateOrderDialog(true);
  };

  const handleCloseUpdateOrderDialog = () => {
    setUpdateOrderDialog(false);
    setOrder(INIT_ORDER);
  };

  const handleOpenContributorDialog = (row, e) => {
    e.stopPropagation();
    setOrder(row);
    setContributorDialog(true);
  };

  const handleCloseContributorDialog = () => {
    setContributorDialog(false);
    setOrder(INIT_ORDER);
  };

  const handleOpenCreateTransactionDialog = (order, e) => {
    e.stopPropagation();
    const missingPrice =
      order.total -
      order.transactions.reduce(
        (acc, transaction) =>
          transaction.status === ORDER_STATUS.DONE
            ? acc + transaction.amountOfMoney
            : acc,
        0
      );
    setTransaction({
      ...INIT_TRANSACTION,
      amountOfMoney: missingPrice,
    });

    setOrder(order);
    setCreateTransactionDialog(true);
  };

  const handleCloseCreateTransactionDialog = () => {
    setCreateTransactionDialog(false);
    setOrder(INIT_ORDER);
    setTransaction(INIT_TRANSACTION);
  };

  const handleOpenUpdateTransactionDialog = (order, transaction) => {
    setTransaction(transaction);
    setOrder(order);
    setUpdateTransactionDialog(true);
  };

  const handleCloseUpdateTransactionDialog = () => {
    setUpdateTransactionDialog(false);
    setOrder(INIT_ORDER);
    setTransaction(INIT_TRANSACTION);
  };

  useEffect(() => {
    if (customer?.id) fetchOrders(true);
  }, [customer?.id, paging.page, status]);

  return (
    <>
      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={8} md={8} lg={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Trạng thái</InputLabel>
            <Select
              labelId="status-select-label"
              value={status}
              onChange={handleChangeStatus}
              size="small"
              label="Trạng thái"
              sx={{ width: "200px" }}
            >
              <MenuItem value={ALL}>Tất cả</MenuItem>
              {Object.keys(ORDER_STATUS_LABEL).map((key) => (
                <MenuItem key={key} value={key}>
                  {ORDER_STATUS_LABEL[key]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={6}>
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="contained"
              startIcon={<Add />}
              color="success"
              sx={{
                paddingTop: "7px",
                paddingBottom: "6.5px",
                marginRight: "5px",
              }}
              onClick={handleOpenOrderDialog}
            >
              Thêm mới
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <StyledTable component={Box}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">STT</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Dịch vụ</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Số tiền</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Thanh toán</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Ngày tạo</Typography>
              </TableCell>
              <TableCell className="header-cell" align="left">
                <Typography className="header-title">Ghi chú</Typography>
              </TableCell>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">Trạng thái</Typography>
              </TableCell>
              <TableCell className="header-cell" align="center">
                <Typography className="header-title">...</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="body-row">
                <TableCell colSpan={7}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              orders.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  index={index}
                  handleOpenUpdateOrderDialog={handleOpenUpdateOrderDialog}
                  handleOpenContributorDialog={handleOpenContributorDialog}
                  handleOpenCreateTransactionDialog={
                    handleOpenCreateTransactionDialog
                  }
                  handleOpenUpdateTransactionDialog={
                    handleOpenUpdateTransactionDialog
                  }
                />
              ))
            )}
          </TableBody>
        </Table>
        {orders && orders.length ? (
          <CustomTableFooter
            total={paging.total}
            page={paging.page}
            onChangePage={handleChangePage}
          />
        ) : (
          !loading && <NoData />
        )}
      </StyledTable>
      <OrderDialog
        open={orderDialog}
        handleClose={handleCloseOrderDialog}
        customer={customer}
        order={order}
        setOrder={setOrder}
        reload={() => fetchOrders(true)}
      />
      <UpdateOrderDialog
        open={updateOrderDialog}
        handleClose={handleCloseUpdateOrderDialog}
        order={order}
        reload={() => fetchOrders(true)}
      />
      <ContributorDialog
        open={contributorDialog}
        handleClose={handleCloseContributorDialog}
        order={order}
        reload={fetchOrders}
      />
      <CreateTransactionDialog
        open={createTransactionDialog}
        handleClose={handleCloseCreateTransactionDialog}
        order={order}
        transaction={transaction}
        setTransaction={setTransaction}
        reload={fetchOrders}
      />
      <UpdateTransactionDialog
        open={updateTransactionDialog}
        handleClose={handleCloseUpdateTransactionDialog}
        order={order}
        transaction={transaction}
        reload={fetchOrders}
      />
    </>
  );
};

export default CustomerOrder;
