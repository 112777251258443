import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Dialog from "../../components/Dialog";
import Title from "../Title";
import { TRANSPARENT_COLOR } from "../../styles/color";
import {
  INIT_ORDER_ITEM_ERROR,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
  PAYMENT_TYPE,
  PAYMENT_TYPE_LABEL,
} from "../../constants/order";
import ChooseOrderItem from "./ChooseOrderItem";
import { formatMoney } from "../../utils/money";
import {
  validateRequired as checkRequired,
  validateInt as checkInt,
} from "../../utils/validate";
import { getTotalMoney } from "./helper";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import CurrencyInput from "../CurrencyInput";

const OrderDialog = ({
  open,
  handleClose,
  customer,
  order,
  setOrder,
  reload,
}) => {
  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsError, setOrderItemsError] = useState([]);
  const [amountOfMoneyError, setAmountOfMoneyError] = useState("");
  const [loading, setLoading] = useState(false);

  const services = useSelector((state) => state.service);

  const initOrderItemsError = (count) => {
    const errors = [];
    for (let index = 0; index < count; index++) {
      errors.push(INIT_ORDER_ITEM_ERROR);
    }
    return errors;
  };

  const handleChangeOrder = (event, field) => {
    let value = event.target.value;
    setOrder((prev) => ({ ...prev, [field]: value }));
    if (field === "amountOfMoney") setAmountOfMoneyError("");
  };

  const validateAmountOfMoney = () => {
    if (order.paymentType === PAYMENT_TYPE.FULL_PAYMENT) return true;
    if (!checkRequired(order.amountOfMoney)) {
      setAmountOfMoneyError("Trường này không được để trống");
      return false;
    }
    const maxMoney = getTotalMoney(orderItems, services);
    if (!checkInt(order.amountOfMoney, 100000, maxMoney)) {
      setAmountOfMoneyError(`Số tiền không hợp lệ`);
      return false;
    }

    return true;
  };

  const validateDiscount = () => {
    let isValid = true;
    const tmpOrderItemsError = [...orderItemsError];
    orderItems.forEach(({ serviceId, discount }, index) => {
      if (serviceId) {
        if (!checkRequired(discount)) {
          isValid = false;
          tmpOrderItemsError[index] = {
            ...tmpOrderItemsError[index],
            discount: "Trường này không được để trống",
          };
        }

        const service = services.find((sv) => sv.id === serviceId);
        if (!checkInt(discount, 0, service.price)) {
          isValid = false;
          tmpOrderItemsError[index] = {
            ...tmpOrderItemsError[index],
            discount: "Số tiền không hợp lệ",
          };
        }
      }
    });
    setOrderItemsError(tmpOrderItemsError);
    return isValid;
  };

  const validateService = () => {
    const tmpOrderItemsError = [...orderItemsError];
    const existService = orderItems.some(({ serviceId }) => serviceId);
    if (!existService) {
      setOrderItemsError(
        tmpOrderItemsError.map((orderItemError) => ({
          ...orderItemError,
          serviceId: "Trường này không được để trống",
        }))
      );
      return false;
    }

    return true;
  };

  const validateNumberOfSessions = () => {
    let isValid = true;
    const tmpOrderItemsError = [...orderItemsError];
    orderItems.forEach(({ serviceId, numberOfSessions }, index) => {
      if (serviceId) {
        if (!checkRequired(numberOfSessions)) {
          isValid = false;
          tmpOrderItemsError[index] = {
            ...tmpOrderItemsError[index],
            numberOfSessions: "Trường này không được để trống",
          };
        }

        const service = services.find((sv) => sv.id === serviceId);
        if (!checkInt(numberOfSessions, 1, service.numberOfSessions)) {
          isValid = false;
          tmpOrderItemsError[index] = {
            ...tmpOrderItemsError[index],
            numberOfSessions: "Số buổi không hợp lệ",
          };
        }
      }
    });
    setOrderItemsError(tmpOrderItemsError);
    return isValid;
  };

  const validate = () =>
    validateService() &&
    validateDiscount() &&
    validateAmountOfMoney() &&
    validateNumberOfSessions();

  const formatOrderData = () => ({
    ...order,
    customerId: customer.id,
    orderItems: orderItems.map((orderItem) => ({
      ...orderItem,
      discount: parseInt(orderItem.discount, 10),
    })),
    amountOfMoney: parseInt(order.amountOfMoney, 10),
  });

  const handleCreateOrder = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.order.createOrder(formatOrderData());
      handleClose();
      if (reload) reload();
      toast.success("Thêm đăng ký sử dụng dịch vụ thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const renderMissingPrice = () =>
    `${formatMoney(
      getTotalMoney(orderItems, services) -
        parseInt(order.amountOfMoney || 0, 10)
    )} VNĐ`;

  useEffect(() => {
    if (open) {
      setOrderItems(order.orderItems);
      setOrderItemsError(initOrderItemsError(order.orderItems.length));
      setAmountOfMoneyError("");
    }
  }, [open, order.orderItems]);

  return (
    <Dialog
      title="Đăng ký dịch vụ"
      subTitle={`${customer.name} - ${customer.phoneNumber}`}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <ChooseOrderItem
          orderItems={orderItems}
          setOrderItems={setOrderItems}
          orderItemsError={orderItemsError}
          setOrderItemsError={setOrderItemsError}
          validateDiscount={validateDiscount}
          validateNumberOfSessions={validateNumberOfSessions}
        />
        <Title title="Thông tin khác" bgColor={TRANSPARENT_COLOR.primary} />
        <Box mb={2}>
          <TextField
            value={order.note || ""}
            size="small"
            fullWidth
            onChange={(event) => handleChangeOrder(event, "note")}
            multiline={true}
            rows={2}
            placeholder="Ghi chú"
          />
        </Box>

        {orderItems.some(({ serviceId }) => serviceId) && (
          <>
            <Title title="Thanh toán" bgColor={TRANSPARENT_COLOR.primary} />
            <Grid container spacing={1} mb={1} alignItems="center">
              <Grid item xs={12} sm={4}>
                <Typography fontWeight={500}>
                  Phương thức thanh toán:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl>
                  <RadioGroup
                    row
                    value={order.method}
                    onChange={(e) => handleChangeOrder(e, "method")}
                  >
                    {Object.keys(PAYMENT_METHOD).map((key) => (
                      <FormControlLabel
                        key={key}
                        value={PAYMENT_METHOD[key]}
                        control={<Radio />}
                        label={PAYMENT_METHOD_LABEL[PAYMENT_METHOD[key]]}
                        sx={{ width: "180px" }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight={500}>Loại thanh toán:</Typography>
              </Grid>
              <Grid item xs={12} sm={8}>
                <FormControl>
                  <RadioGroup
                    row
                    value={order.paymentType}
                    onChange={(e) => handleChangeOrder(e, "paymentType")}
                  >
                    {Object.keys(PAYMENT_TYPE).map((key) => (
                      <FormControlLabel
                        key={key}
                        value={PAYMENT_TYPE[key]}
                        control={<Radio />}
                        label={PAYMENT_TYPE_LABEL[PAYMENT_TYPE[key]]}
                        sx={{ width: "180px" }}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {order.paymentType === PAYMENT_TYPE.PARTIAL_PAYMENT && (
                <>
                  <Grid item xs={12} sm={4}>
                    <Typography fontWeight={500}>
                      Số tiền thanh toán lần đầu:
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyInput
                      value={order.amountOfMoney}
                      onChange={(e) => handleChangeOrder(e, "amountOfMoney")}
                      onBlur={validateAmountOfMoney}
                      error={!!amountOfMoneyError}
                      helperText={amountOfMoneyError}
                    />
                    {/* <TextField
                      error={!!amountOfMoneyError}
                      helperText={amountOfMoneyError}
                      value={order.amountOfMoney}
                      size="small"
                      fullWidth
                      onChange={(e) => handleChangeOrder(e, "amountOfMoney")}
                      onBlur={validateAmountOfMoney}
                      type="number"
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Typography align="right" fontWeight={500} fontSize="18px">
                      Còn lại: {renderMissingPrice()}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          Hủy
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleCreateOrder}
        >
          Thêm mới
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDialog;
