import UserRevenue from "./UserRevenue";
import UserWorkTime from "./UserWorkTime";

const tabs = [
  {
    name: "Doanh thu",
    value: "1",
    component: UserRevenue,
  },
  {
    name: "Thời gian tour",
    value: "2",
    component: UserWorkTime,
  },
];

export default tabs;
