import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Backdrop, CircularProgress } from "@mui/material";

import Layout from "../containers/Layout";
import { PERMISSION_TYPE } from "../constants/permission";

import appRoutes from "./appRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import axiosClient from "../apis/api";
import apis from "../apis";
import { handleCallApiError } from "../errors";
import { setCurrentUser } from "../stores/userSlice";
import {
  setCurrentRole,
  setIsMasterRole,
  setPermissions,
} from "../stores/roleSlice";
import { setServices } from "../stores/serviceSlice";
import { setProvinces } from "../stores/provinceSlice";

const PrivateApp = () => {
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const { isMasterRole, permissions } = useSelector((state) => state.role);
  const privateRoutes = appRoutes.filter((route) => route.isPrivate);
  (() => {
    axiosClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  })();

  const dispatch = useDispatch();
  const history = useHistory();

  const init = async () => {
    setLoading(true);
    await Promise.all([getMe(), getServices(), getProvinces()]);
    setLoading(false);
  };

  const getMe = async () => {
    try {
      const res = await apis.auth.getMe();
      dispatch(setCurrentUser(res.me));
      dispatch(setCurrentRole(res.me.roleId));
      await getRole(res.me.roleId);
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const getServices = async () => {
    try {
      const res = await apis.service.getServices({ active: true });
      dispatch(setServices(res.services));
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const getProvinces = async () => {
    try {
      const res = await apis.address.getProvinces();
      dispatch(setProvinces(res.provinces));
    } catch (error) {
      handleCallApiError(error);
    }
  };

  const getRole = async (roleId) => {
    try {
      const {
        role: { permissions = [], isMasterRole = false },
      } = await apis.role.getRole(roleId);
      dispatch(setIsMasterRole(isMasterRole));
      const menuPermissions = permissions
        .filter((permission) => permission.type === PERMISSION_TYPE.MENU)
        .map((permission) => permission.path);
      dispatch(setPermissions(menuPermissions));
      if (menuPermissions[0]) history.push(menuPermissions[0]);
    } catch (error) {
      handleCallApiError(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#ffffff",
        }}
        open={loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    );
  }

  return (
    <Layout>
      <Switch>
        {privateRoutes
          .filter(
            (privateRoute) =>
              isMasterRole || permissions.includes(privateRoute.path)
          )
          .map((privateRoute) => (
            <PrivateRoute
              path={privateRoute.path}
              component={privateRoute.component}
              exact
              key={privateRoute.path}
            />
          ))}
      </Switch>
    </Layout>
  );
};

const AppRouter = () => {
  const publicRoutes = appRoutes.filter((route) => !route.isPrivate);

  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((publicRoute) => (
          <PublicRoute
            exact
            path={publicRoute.path}
            component={publicRoute.component}
            restricted={publicRoute.restricted}
            key={publicRoute.path}
          />
        ))}

        <PrivateRoute component={PrivateApp} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
