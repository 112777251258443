import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControl,
  Select,
  FormHelperText,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Dialog from "../../components/Dialog";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import { COLOR } from "../../styles/color";
import { GENDER } from "../../constants";
import {
  validatePhoneNumber as checkPhoneNumber,
  validateRequired as checkRequired,
  validateDate as checkDate,
} from "../../utils/validate";

const INIT_ERROR_CUSTOMER = {
  name: "",
  phoneNumber: "",
  gender: "",
  dateOfBirth: "",
  provinceId: "",
  districtId: "",
  wardId: "",
};

const CustomerDialog = ({
  open,
  handleClose,
  customer,
  setCustomer,
  handleReload,
  dialogType,
}) => {
  const [customerError, setCustomerError] = useState(INIT_ERROR_CUSTOMER);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);

  const provinces = useSelector((state) => state.province);

  const handleChangeCustomer = (event, field) => {
    const value = event.target.value;
    setCustomer((prev) => ({ ...prev, [field]: value }));
    setCustomerError((prev) => ({ ...prev, [field]: false }));
  };

  const handleChangeActive = (event) => {
    setCustomer((prev) => ({ ...prev, active: event.target.checked }));
  };

  const handleChangeDate = (newValue) => {
    setCustomer((prev) => ({ ...prev, dateOfBirth: newValue }));
    setCustomerError((prev) => ({ ...prev, dateOfBirth: false }));
  };

  const handleChangeProvince = async (newValue) => {
    setCustomer((prev) => ({ ...prev, provinceId: newValue }));
    setCustomerError((prev) => ({ ...prev, provinceId: false }));
    if (newValue) {
      await fetchDistricts(newValue);
    } else {
      setDistricts([]);
      setWards([]);
      setCustomer((prev) => ({ ...prev, districtId: null, wardId: null }));
    }
  };

  const handleChangeDistrict = async (newValue) => {
    setCustomer((prev) => ({ ...prev, districtId: newValue }));
    setCustomerError((prev) => ({ ...prev, districtId: false }));
    if (newValue) {
      await fetchWards(newValue);
    } else {
      setWards([]);
      setCustomer((prev) => ({ ...prev, wardId: null }));
    }
  };

  const handleChangeWard = async (newValue) => {
    setCustomer((prev) => ({ ...prev, wardId: newValue }));
    setCustomerError((prev) => ({ ...prev, wardId: false }));
  };

  const fetchDistricts = async (provinceId) => {
    setAdding(true);
    try {
      const res = await apis.address.getDistricts(provinceId);
      setDistricts(res?.districts);
    } catch (error) {
      handleCallApiError(error);
    }
    setAdding(false);
  };

  const fetchWards = async (districtId) => {
    setAdding(true);
    try {
      const res = await apis.address.getWards(districtId);
      setWards(res?.wards);
    } catch (error) {
      handleCallApiError(error);
    }
    setAdding(false);
  };

  const handleCloseDialog = () => {
    handleClose();
    setCustomerError(INIT_ERROR_CUSTOMER);
    setCustomer({});
    setDistricts([]);
    setWards([]);
  };

  const validateRequired = (field) => {
    if (!checkRequired(customer[field])) {
      setCustomerError((prev) => ({
        ...prev,
        [field]: "Trường này không được để trống",
      }));
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (field) => {
    if (customer[field] && !checkPhoneNumber(customer[field])) {
      setCustomerError((prev) => ({
        ...prev,
        [field]: "Số điện thoại không hợp lệ",
      }));
      return false;
    }
    return true;
  };

  const validateDate = (field) => {
    if (customer[field] && !checkDate(customer[field], "DD/MM/YYYY")) {
      setCustomerError((prev) => ({
        ...prev,
        [field]: "Ngày không hợp lệ",
      }));
      return false;
    }

    return true;
  };

  const validate = () =>
    validateRequired("name") &&
    validateRequired("gender") &&
    validateRequired("phoneNumber") &&
    validatePhoneNumber("phoneNumber") &&
    validateRequired("dateOfBirth") &&
    validateDate("dateOfBirth") &&
    validateRequired("provinceId") &&
    validateRequired("districtId") &&
    validateRequired("wardId");

  const handleCreateService = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.customer.createCustomer({
        ...customer,
        dateOfBirth: dayjs(customer.dateOfBirth).format("DD/MM/YYYY"),
      });
      handleCloseDialog();
      toast.success("Thêm khách hàng mới thành công");
      handleReload();
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const handleUpdateService = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const res = await apis.customer.updateCustomer(customer.id, {
        ...customer,
        dateOfBirth: dayjs(customer.dateOfBirth).format("DD/MM/YYYY"),
      });
      if (res.status === 1) {
        handleCloseDialog();
        toast.success("Cập nhật thông tin thành công");
        handleReload();
      } else {
        toast.error("Cập nhật thông tin thất bại");
      }
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const renderName = (type, id) => {
    switch (type) {
      case "province":
        const province = provinces.find((p) => p.id === id);
        return province?.name || "";
      case "district":
        const district = districts.find((p) => p.id === id);
        return district?.name || "";
      case "ward":
        const ward = wards.find((p) => p.id === id);
        return ward?.name || "";
      default:
        return "Đang tải...";
    }
  };

  useEffect(() => {
    if (open && customer?.provinceId) fetchDistricts(customer?.provinceId);
  }, [open, customer?.provinceId]);

  useEffect(() => {
    if (open && customer?.districtId) fetchWards(customer?.districtId);
  }, [open, customer?.districtId]);

  return (
    <Dialog
      title={
        dialogType === "create"
          ? "Thêm khách hàng"
          : "Cập nhật thông tin khách hàng"
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {customer && Object.keys(customer).length > 0 && (
          <Box>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={8}>
                <Typography fontWeight={500} mb={1}>
                  Tên khách hàng <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <TextField
                  error={!!customerError.name}
                  helperText={customerError.name}
                  value={customer.name}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeCustomer(event, "name")}
                  onBlur={() => validateRequired("name")}
                  placeholder="Tên khách hàng"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight={500} mb={1}>
                  Giới tính <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <FormControl fullWidth error={!!customerError.gender}>
                  <Select
                    value={customer.gender}
                    onChange={(event) => handleChangeCustomer(event, "gender")}
                    onBlur={() => validateRequired("gender")}
                    size="small"
                  >
                    {Object.keys(GENDER).map((key) => (
                      <MenuItem key={key} value={key.toLowerCase()}>
                        {GENDER[key]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{customerError.gender}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Số điện thoại <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <TextField
                  error={!!customerError.phoneNumber}
                  helperText={customerError.phoneNumber}
                  value={customer.phoneNumber}
                  size="small"
                  fullWidth
                  onChange={(event) =>
                    handleChangeCustomer(event, "phoneNumber")
                  }
                  onBlur={() =>
                    validateRequired("phoneNumber") &&
                    validatePhoneNumber("phoneNumber")
                  }
                  placeholder="Số điện thoại"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Ngày sinh <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="startDate"
                    inputFormat="DD/MM/YYYY"
                    value={customer.dateOfBirth}
                    onChange={(newValue) => handleChangeDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        error={!!customerError.dateOfBirth}
                        helperText={customerError.dateOfBirth}
                        onBlur={() =>
                          validateRequired("dateOfBirth") &&
                          validateDate("dateOfBirth")
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight={500} mb={1}>
                  Tỉnh/Thành phố <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <Autocomplete
                  size="small"
                  value={customer.provinceId}
                  options={provinces.map((province) => province.id)}
                  getOptionLabel={(option) => renderName("province", option)}
                  filterSelectedOptions
                  onChange={(event, newValue) => handleChangeProvince(newValue)}
                  onBlur={() => validateRequired("provinceId")}
                  renderOption={(props, key) => (
                    <MenuItem value={key} {...props}>
                      {renderName("province", key)}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!customerError.provinceId}
                      helperText={customerError.provinceId}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight={500} mb={1}>
                  Huyện/Thị xã <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <Autocomplete
                  size="small"
                  value={customer.districtId}
                  options={districts.map((district) => district.id)}
                  getOptionLabel={(option) => renderName("district", option)}
                  filterSelectedOptions
                  onChange={(event, newValue) => handleChangeDistrict(newValue)}
                  onBlur={() => validateRequired("districtId")}
                  renderOption={(props, key) => (
                    <MenuItem value={key} {...props}>
                      {renderName("district", key)}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!customerError.districtId}
                      helperText={customerError.districtId}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography fontWeight={500} mb={1}>
                  Xã/Phường <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <Autocomplete
                  size="small"
                  value={customer.wardId}
                  options={wards.map((ward) => ward.id)}
                  getOptionLabel={(option) => renderName("ward", option)}
                  filterSelectedOptions
                  onChange={(event, newValue) => handleChangeWard(newValue)}
                  onBlur={() => validateRequired("wardId")}
                  renderOption={(props, key) => (
                    <MenuItem value={key} {...props}>
                      {renderName("ward", key)}
                    </MenuItem>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={!!customerError.wardId}
                      helperText={customerError.wardId}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography fontWeight={500} mb={1}>
                  Ghi chú:
                </Typography>
                <TextField
                  value={customer.note || ""}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeCustomer(event, "note")}
                  multiline={true}
                  rows={2}
                  placeholder="Ghi chú"
                />
              </Grid>
            </Grid>
            {dialogType === "update" && (
              <Stack
                mb={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Trạng thái:</Typography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!!customer.active}
                      onChange={handleChangeActive}
                    />
                  }
                  label={customer.active ? "Hoạt động" : "Không hoạt động"}
                />
              </Stack>
            )}
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        {adding && (
          <Box mr={1} pt={0.8}>
            <CircularProgress size={20} />
          </Box>
        )}
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>
        {dialogType === "create" ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreateService}
          >
            Thêm mới
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdateService}
          >
            Lưu thay đổi
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomerDialog;
