import api from "./api";

const getCustomers = async ({ search, limit, offset }) => {
  const response = await api({
    method: "GET",
    url: "/customers",
    params: { search, limit, offset },
  });

  return response;
};

const createCustomer = async (data) => {
  const response = await api({
    method: "POST",
    url: "/customers",
    data,
  });

  return response;
};

const updateCustomer = async (userId, data) => {
  const response = await api({
    method: "PUT",
    url: `/customers/${userId}`,
    data,
  });

  return response;
};

const getCustomer = async (customerId) => {
  const response = await api({
    method: "GET",
    url: `/customers/${customerId}`,
  });

  return response;
};

export { getCustomers, createCustomer, updateCustomer, getCustomer };
