import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";
import { ORDER_STATUS } from "../../constants/order";

const UpdateOrderDialog = ({ open, handleClose, order, reload }) => {
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    handleClose();
    setIsCancel(false);
    setNote("");
    setNoteError("");
  };

  const handleUpdateOrder = async () => {
    if (isCancel && !note) {
      setNoteError("Trường này không dược để trống");
      return;
    }
    setLoading(true);
    try {
      await apis.order.updateOrder(order.id, { note, isCancel });
      handleCloseDialog();
      reload();
      toast.success("Cập nhật đăng ký dịch vụ thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setNote(order.note || "");
    }
  }, [open, order.note]);

  return (
    <Dialog
      title="Cập nhật đăng ký dịch vụ"
      subTitle={
        order?.orderItems?.map((orderItem) => orderItem.name).join(", ") || ""
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Box mb={2}>
          <Typography fontWeight={500} mb={1}>
            Ghi chú:
          </Typography>
          <TextField
            error={!!noteError}
            helperText={noteError}
            value={note}
            size="small"
            fullWidth
            onChange={(event) => setNote(event.target.value)}
            multiline={true}
            rows={2}
          />
        </Box>
        {order.status !== ORDER_STATUS.CANCELLED && (
          <>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    color="error"
                    checked={isCancel}
                    onChange={(event) => setIsCancel(event.target.checked)}
                  />
                }
                label={<Typography color="error">Hủy đăng ký này</Typography>}
              />
            </FormGroup>
            {isCancel && (
              <Typography textAlign="center" fontStyle="italic">
                <span style={{ fontWeight: 600 }}>Lưu ý:</span> Hành động này
                không thể hoàn tác !
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>

        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleUpdateOrder}
        >
          Lưu thay đổi
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateOrderDialog;
