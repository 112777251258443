import api from "./api";

const getContributions = async (params) => {
  const response = await api({
    method: "GET",
    url: "/contributions",
    params,
  });

  return response;
};

export { getContributions };
