import React, { useEffect, useState } from "react";
import { Add, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";

import UserList from "./UserList";
import UserDialog from "./UserDialog";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { INIT_USER } from "../../constants/user";
import { ALL, INITIAL_PAGING } from "../../constants";

const User = () => {
  const [search, setSearch] = useState("");
  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [userDialog, setUserDialog] = useState(false);
  const [user, setUser] = useState({});
  const [reload, setReload] = useState(false);
  const [roleId, setRoleId] = useState(ALL);
  const [roles, setRoles] = useState([]);

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setSearch(value);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleChangeRoleId = (event) => {
    const { value } = event.target;
    setRoleId(value);
    handleChangePaging({ ...paging, page: INITIAL_PAGING.page });
  };

  const handleChangePaging = ({ page, total }) => {
    setPaging({ page: page ?? 1, total: total ?? 0 });
  };

  const handleOpenUserDialog = () => {
    setUser(INIT_USER);
    setUserDialog(true);
  };

  const handleCloseUserDialog = () => {
    setUserDialog(false);
  };

  const handleReload = () => {
    setReload((prev) => !prev);
  };

  const fetchRoles = async () => {
    try {
      const res = await apis.role.getRoles();
      setRoles(res.roles);
    } catch (error) {
      handleCallApiError(error);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <>
      <Box padding={2}>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={5}>
            <TextField
              value={search}
              size="small"
              fullWidth
              placeholder={"Tìm kiếm theo tên hoặc số điện thoại"}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Vai trò</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={roleId}
                onChange={handleChangeRoleId}
                size="small"
                label="Vai trò"
                fullWidth
              >
                <MenuItem value={ALL}>Tất cả</MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={5}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                color="success"
                sx={{
                  paddingTop: "7px",
                  paddingBottom: "6.5px",
                  marginRight: "5px",
                }}
                onClick={handleOpenUserDialog}
              >
                Thêm mới
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <UserList
          search={search}
          paging={paging}
          onChangePage={handleChangePaging}
          reload={reload}
          roleId={roleId}
          roles={roles}
        />
      </Box>
      <UserDialog
        open={userDialog}
        handleClose={handleCloseUserDialog}
        user={user}
        setUser={setUser}
        handleReload={handleReload}
        roles={roles}
        dialogType="create"
      />
    </>
  );
};

export default User;
