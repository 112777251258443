import React, { useState } from "react";
import { toast } from "react-toastify";
import { Stack, Typography } from "@mui/material";
import { Save } from "@mui/icons-material";

import { StyledPermission } from "./index.style";
import PermissionList from "./PermissionList";
import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { LoadingButton } from "@mui/lab";

const Permission = ({
  selectedRole,
  setSelectedRole,
  groupPermissions,
  setReload,
}) => {
  const [loading, setLoading] = useState(false);

  const handleSelectPermission = (permission) => {
    let newPermissions = [...selectedRole.permissions];
    if (selectedRole.permissions.map((pms) => pms.id).includes(permission.id)) {
      newPermissions = newPermissions.filter((el) => el.id !== permission.id);
    } else {
      newPermissions.push(permission);
    }

    setSelectedRole({
      ...selectedRole,
      permissions: newPermissions,
    });
  };

  const handleSaveChange = async () => {
    setLoading(true);
    try {
      const permissionIds = selectedRole.permissions.map((pms) => pms.id);
      await apis.role.attachPermissionToRole(selectedRole.id, permissionIds);
      setReload((prev) => !prev);
      toast.success("Thêm vai trò thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">Danh sách quyền</Typography>

        {!selectedRole.isMasterRole && (
          <LoadingButton
            loading={loading}
            variant="contained"
            color="success"
            startIcon={<Save />}
            onClick={handleSaveChange}
          >
            Lưu thay đổi
          </LoadingButton>
        )}
      </Stack>
      {selectedRole.isMasterRole ? (
        <Typography>Tất cả các quyền có trong hệ thống</Typography>
      ) : (
        <StyledPermission>
          {groupPermissions.map((el) => (
            <PermissionList
              key={el.id}
              title={el.name}
              permissions={
                el.permissions &&
                el.permissions.map((permission) => {
                  if (
                    selectedRole.permissions
                      .map((pms) => pms.id)
                      .includes(permission.id)
                  ) {
                    return { ...permission, checked: true };
                  }
                  return { ...permission, checked: false };
                })
              }
              onSelectPermission={handleSelectPermission}
            />
          ))}
        </StyledPermission>
      )}
    </>
  );
};

export default Permission;
