import React, { useState } from "react";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";

import Copyright from "../../components/Copyright";

import apis from "../../apis";
import { login } from "../../stores/authSlice";
import { handleCallApiError } from "../../errors";
import { StyledLogin } from "./index.style";
import { validatePhoneNumber as checkPhoneNumber } from "../../utils/validate";

const ERROR_LOGIN = {
  phoneNumber: "",
  password: "",
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState(ERROR_LOGIN);
  const dispatch = useDispatch();

  const validatePhoneNumber = () => {
    if (!phoneNumber) {
      setErrorLogin((prev) => ({
        ...prev,
        phoneNumber: "Trường này không đươc để trống",
      }));
      return false;
    }

    if (!checkPhoneNumber(phoneNumber)) {
      setErrorLogin((prev) => ({
        ...prev,
        phoneNumber: "Số điện thoại không đúng định dạng",
      }));
      return false;
    }

    return true;
  };

  const validatePassword = () => {
    if (!password) {
      setErrorLogin((prev) => ({
        ...prev,
        password: "Trường này không đươc để trống",
      }));
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validatePhoneNumber() || !validatePassword()) return;

    setLoading(true);
    try {
      const res = await apis.auth.login({
        phoneNumber,
        password,
      });
      setLoading(false);
      dispatch(login(res.token));
      toast.success("Đăng nhập thành công");
    } catch (error) {
      setLoading(false);
      handleCallApiError(error);
    }
  };

  return (
    <>
      <StyledLogin>
        <Typography variant="h4" component="h1" align="center" mb={3}>
          Đăng nhập
        </Typography>
        <Box mb={2}>
          <Typography fontWeight={500} mb={1}>
            Số điện thoại
          </Typography>
          <TextField
            helperText={errorLogin.phoneNumber}
            error={!!errorLogin.phoneNumber}
            value={phoneNumber}
            size="small"
            fullWidth
            onChange={(event) => {
              setPhoneNumber(event.target.value);
              setErrorLogin((prev) => ({ ...prev, phoneNumber: "" }));
            }}
            onBlur={validatePhoneNumber}
          />
        </Box>
        <Box mb={3}>
          <Typography fontWeight={500} mb={1}>
            Mật khẩu
          </Typography>
          <TextField
            helperText={errorLogin.password}
            error={!!errorLogin.password}
            value={password}
            size="small"
            fullWidth
            onChange={(event) => {
              setPassword(event.target.value);
              setErrorLogin((prev) => ({ ...prev, password: "" }));
            }}
            onBlur={validatePassword}
            type="password"
          />
        </Box>
        <LoadingButton
          // size="small"
          loading={loading}
          variant="contained"
          fullWidth
          onClick={handleSubmit}
        >
          Đăng nhập
        </LoadingButton>
      </StyledLogin>
      <Box
        component="footer"
        sx={{
          p: 2,
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          bgcolor: "#ffffff",
        }}
      >
        <Copyright />
      </Box>
    </>
  );
};

export default Login;
