import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";
import {
  ORDER_STATUS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
} from "../../constants/order";

const UpdateTransactionDialog = ({
  open,
  handleClose,
  order,
  transaction,
  reload,
}) => {
  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");
  const [isCancel, setIsCancel] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = () => {
    handleClose();
    setIsCancel(false);
    setNote("");
    setNoteError("");
  };

  const handleUpdateTransaction = async () => {
    if (isCancel && !note) {
      setNoteError("Trường này không dược để trống");
      return;
    }
    setLoading(true);
    try {
      await apis.order.updateTransaction(order.id, {
        transactionId: transaction.id,
        note,
        isCancel,
      });
      handleCloseDialog();
      reload();
      toast.success(
        `Cập nhật thanh toán lần ${transaction.index + 1} thành công`
      );
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      setNote(order.note || "");
    }
  }, [open, order.note]);

  return (
    <Dialog
      title={`Cập nhật thanh toán lần ${transaction.index + 1}`}
      subTitle={
        order?.orderItems?.map((orderItem) => orderItem.name).join(", ") || ""
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Grid container spacing={1} alignItems="center" mb={1}>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={500}>Phương thức thanh toán:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl>
              <RadioGroup row value={transaction.method}>
                {Object.keys(PAYMENT_METHOD).map((key) => (
                  <FormControlLabel
                    key={key}
                    value={PAYMENT_METHOD[key]}
                    control={<Radio />}
                    label={PAYMENT_METHOD_LABEL[PAYMENT_METHOD[key]]}
                    sx={{ marginRight: "30px" }}
                    disabled={true}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={500}>Số tiền thanh toán:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled={true}
              value={transaction.amountOfMoney}
              size="small"
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography fontWeight={500} mb={1}>
              Ghi chú:
            </Typography>
            <TextField
              error={!!noteError}
              helperText={noteError}
              value={note}
              size="small"
              fullWidth
              onChange={(event) => setNote(event.target.value)}
              multiline={true}
              rows={2}
            />
          </Grid>
          {transaction.status === ORDER_STATUS.DONE && (
            <Grid item xs={12} sm={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="error"
                      checked={isCancel}
                      onChange={(event) => setIsCancel(event.target.checked)}
                    />
                  }
                  label={
                    <Typography color="error">Hủy thanh toán này</Typography>
                  }
                />
              </FormGroup>
              {isCancel && (
                <Typography textAlign="center" fontStyle="italic">
                  <span style={{ fontWeight: 600 }}>Lưu ý:</span> Hành động này
                  không thể hoàn tác !
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>

        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleUpdateTransaction}
        >
          Lưu thay đổi
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateTransactionDialog;
