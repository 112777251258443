import { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import Dialog from "../../components/Dialog";
import {
  ORDER_STATUS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_LABEL,
} from "../../constants/order";
import { formatMoney } from "../../utils/money";
import {
  validateRequired as checkRequired,
  validateInt as checkInt,
} from "../../utils/validate";
import CurrencyInput from "../../components/CurrencyInput";

const CreateTransactionDialog = ({
  open,
  handleClose,
  order,
  transaction,
  setTransaction,
  reload,
}) => {
  const [amountOfMoneyError, setAmountOfMoneyError] = useState("");
  const [loading, setLoading] = useState(false);

  const getMissingPrice = () => {
    const { total, transactions = [] } = order;
    return (
      total -
      transactions.reduce(
        (acc, t) =>
          t.status === ORDER_STATUS.DONE ? acc + t.amountOfMoney : acc,
        0
      ) -
      parseInt(transaction.amountOfMoney, 10)
    );
  };

  const handleCloseDialog = () => {
    handleClose();
    setAmountOfMoneyError("");
  };

  const handleChangeTransaction = (value, field) => {
    setTransaction((prev) => ({ ...prev, [field]: value }));
    if (field === "amountOfMoney") setAmountOfMoneyError("");
  };

  const validateAmountOfMoney = () => {
    if (!checkRequired(transaction.amountOfMoney)) {
      setAmountOfMoneyError("Trường này không được để trống");
      return false;
    }

    const { total, transactions = [] } = order;
    const maxMoney =
      total -
      transactions.reduce(
        (acc, t) =>
          t.status === ORDER_STATUS.DONE ? acc + t.amountOfMoney : acc,
        0
      );
    if (!checkInt(transaction.amountOfMoney, 100000, maxMoney)) {
      setAmountOfMoneyError(`Số tiền không hợp lệ`);
      return false;
    }

    return true;
  };

  const handleCreateTransaction = async () => {
    if (!validateAmountOfMoney()) return;
    setLoading(true);
    try {
      await apis.order.addTransaction(order.id, transaction);
      handleCloseDialog();
      reload();
      toast.success(
        `Thanh toán lần ${order.transactions?.length || 0 + 1} thành công`
      );
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  return (
    <Dialog
      title={`Thanh toán lần ${order.transactions?.length || 0 + 1}`}
      subTitle={
        order?.orderItems?.map((orderItem) => orderItem.name).join(", ") || ""
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={500}>Phương thức thanh toán:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl>
              <RadioGroup
                row
                value={transaction.method}
                onChange={(e) =>
                  handleChangeTransaction(e.target.value, "method")
                }
              >
                {Object.keys(PAYMENT_METHOD).map((key) => (
                  <FormControlLabel
                    key={key}
                    value={PAYMENT_METHOD[key]}
                    control={<Radio />}
                    label={PAYMENT_METHOD_LABEL[PAYMENT_METHOD[key]]}
                    sx={{ marginRight: "30px" }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography fontWeight={500}>Số tiền thanh toán:</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencyInput
              value={transaction.amountOfMoney}
              onChange={(e) =>
                handleChangeTransaction(e.target.value, "amountOfMoney")
              }
              onBlur={validateAmountOfMoney}
              error={!!amountOfMoneyError}
              helperText={amountOfMoneyError}
            />
            {/* <TextField
              error={!!amountOfMoneyError}
              helperText={amountOfMoneyError}
              value={transaction.amountOfMoney}
              size="small"
              fullWidth
              onChange={(e) =>
                handleChangeTransaction(e.target.value, "amountOfMoney")
              }
              onBlur={validateAmountOfMoney}
              type="number"
            /> */}
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography align="right" fontWeight={500} fontSize="18px">
              Còn lại: {`${formatMoney(getMissingPrice())} VNĐ`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography fontWeight={500} mb={1}>
              Ghi chú:
            </Typography>
            <TextField
              value={transaction.note || ""}
              size="small"
              fullWidth
              onChange={(event) =>
                handleChangeTransaction(event.target.value, "note")
              }
              multiline={true}
              rows={2}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>
        <LoadingButton
          variant="contained"
          color="success"
          loading={loading}
          onClick={handleCreateTransaction}
        >
          Thêm mới
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTransactionDialog;
