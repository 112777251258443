import { styled } from "@mui/material/styles";
import { COLOR } from "../../styles/color";
import { Box } from "@mui/material";

export const StyledRoleList = styled(Box)`
  .warper {
    border-right: 1px solid ${COLOR.dark};
    height: calc(100vh - 180px);
    padding-right: 10px;
  }
  .option-field-content {
    max-height: 525px;
    overflow-y: auto;
    margin-bottom: 20px;
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${COLOR.borderDark};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${COLOR.lightBlue};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${COLOR.primaryDark};
    }
  }
`;

export const StyledPermissionList = styled(Box)`
  padding: 10px 0px;
  border-bottom: 1px solid #ebe9f1;

  .header-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 25px;
  }

  .item {
    padding-top: 0 !important;

    .checkbox {
      .MuiTypography-root {
        color: ${COLOR.dark};
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;

export const StyledPermission = styled(Box)`
  max-height: calc(100vh - 190px);
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px ${COLOR.borderDark};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLOR.lightBlue};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${COLOR.primaryDark};
  }
`;
