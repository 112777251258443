import api from "./api";

const getServices = async (params) => {
  const response = await api({
    method: "GET",
    url: "/services",
    params,
  });

  return response;
};

const createService = async (data) => {
  const response = await api({
    method: "POST",
    url: "/services",
    data,
  });

  return response;
};

const updateService = async (userId, data) => {
  const response = await api({
    method: "PUT",
    url: `/services/${userId}`,
    data,
  });

  return response;
};

export { getServices, createService, updateService };
