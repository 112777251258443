import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import Dialog from "../../components/Dialog";
import { INIT_USER_ERROR } from "../../constants/user";
import { handleCallApiError } from "../../errors";
import apis from "../../apis";
import {
  validateEmail as checkEmail,
  validatePhoneNumber as checkPhoneNumber,
  validateDate as checkDate,
} from "../../utils/validate";
import { COLOR } from "../../styles/color";

const UserDialog = ({
  open,
  handleClose,
  user,
  setUser,
  handleReload,
  roles,
  dialogType,
}) => {
  const [userError, setUserError] = useState(INIT_USER_ERROR);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(true);

  const handleChangeUser = (event, field) => {
    const value = event.target.value;
    setUser((prev) => ({ ...prev, [field]: value }));
    setUserError((prev) => ({ ...prev, [field]: false }));
  };

  const handleChangeDate = (newValue) => {
    setUser((prev) => ({ ...prev, dateOfBirth: newValue }));
    setUserError((prev) => ({ ...prev, dateOfBirth: false }));
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const handleCloseDialog = () => {
    handleClose();
    setUserError(INIT_USER_ERROR);
    setUser({});
  };

  const validateName = () => {
    if (user.name === "") {
      setUserError((prev) => ({
        ...prev,
        name: "Trường này không được để trống",
      }));
      return false;
    }

    return true;
  };

  const validateEmail = () => {
    if (user.email && !checkEmail(user.email)) {
      setUserError((prev) => ({
        ...prev,
        email: "Email không đúng định dạng",
      }));
      return false;
    }

    return true;
  };

  const validateDate = () => {
    if (user.dateOfBirth && !checkDate(user.dateOfBirth, "DD/MM/YYYY")) {
      setUserError((prev) => ({
        ...prev,
        dateOfBirth: "Ngày sinh không hợp lệ",
      }));
      return false;
    }

    return true;
  };

  const validatePhoneNumber = () => {
    if (user.phoneNumber === "") {
      setUserError((prev) => ({
        ...prev,
        phoneNumber: "Trường này không được để trống",
      }));
      return false;
    }

    if (!checkPhoneNumber(user.phoneNumber)) {
      setUserError((prev) => ({
        ...prev,
        phoneNumber: "Số điện thoại không hợp lệ",
      }));
      return false;
    }
    return true;
  };

  const validateRoleId = () => {
    if (user.roleId === "") {
      setUserError((prev) => ({
        ...prev,
        roleId: "Trường này không được để trống",
      }));
      return false;
    }

    return true;
  };

  const validate = () =>
    validateName() &&
    validatePhoneNumber() &&
    validateEmail() &&
    validateDate() &&
    validateRoleId();

  const handleCreateUser = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.user.createUser({
        ...user,
        dateOfBirth: user.dateOfBirth
          ? dayjs(user.dateOfBirth).format("DD/MM/YYYY")
          : null,
      });
      handleCloseDialog();
      toast.success("Thêm nhân sự mới thành công");
      handleReload();
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  const handleUpdateUser = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const res = await apis.user.updateUser(user.id, {
        ...user,
        active: status,
        dateOfBirth: user.dateOfBirth
          ? dayjs(user.dateOfBirth).format("DD/MM/YYYY")
          : null,
      });
      if (res.status === 1) {
        handleCloseDialog();
        toast.success("Cập nhật thông tin thành công");
        handleReload();
      } else {
        toast.error("Cập nhật thông tin thất bại");
      }
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setStatus(user?.active || true);
  }, [user]);

  return (
    <Dialog
      title={
        dialogType === "create" ? "Thêm nhân sự" : "Cập nhật thông tin nhân sự"
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {user && Object.keys(user).length > 0 && (
          <Box>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Họ và tên <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <TextField
                  error={!!userError.name}
                  helperText={userError.name}
                  value={user.name}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeUser(event, "name")}
                  onBlur={validateName}
                  placeholder="Họ và tên"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Số điện thoại <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <TextField
                  error={!!userError.phoneNumber}
                  helperText={userError.phoneNumber}
                  value={user.phoneNumber}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeUser(event, "phoneNumber")}
                  onBlur={validatePhoneNumber}
                  placeholder="Số điện thoại"
                  disabled={dialogType === "update"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Địa chỉ email:
                </Typography>
                <TextField
                  error={!!userError.email}
                  helperText={userError.email}
                  value={user.email}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeUser(event, "email")}
                  onBlur={validateEmail}
                  placeholder="Địa chỉ email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Ngày sinh:
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    name="startDate"
                    inputFormat="DD/MM/YYYY"
                    value={user.dateOfBirth}
                    onChange={(newValue) => handleChangeDate(newValue)}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        size="small"
                        {...params}
                        onBlur={validateDate}
                        error={!!userError.dateOfBirth}
                        helperText={userError.dateOfBirth}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Mật khẩu:
                </Typography>
                <TextField
                  error={!!userError.password}
                  helperText={userError.password}
                  value={user.password}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeUser(event, "password")}
                  placeholder="Mật khẩu"
                  type="password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  Vai trò <span style={{ color: COLOR.error }}>*</span>:
                </Typography>
                <FormControl fullWidth error={!!userError.roleId}>
                  <Select
                    value={user.roleId}
                    onChange={(event) => handleChangeUser(event, "roleId")}
                    onBlur={validateRoleId}
                    size="small"
                  >
                    {roles
                      .filter((role) => !role.isMasterRole)
                      .map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{userError.roleId}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {dialogType === "update" && (
              <Stack
                mb={3}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Trạng thái:</Typography>
                <FormControlLabel
                  control={
                    <Switch checked={!!status} onChange={handleChangeStatus} />
                  }
                  label={status ? "Hoạt động" : "Không hoạt động"}
                />
              </Stack>
            )}
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          Hủy
        </Button>
        {dialogType === "create" ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreateUser}
          >
            Thêm mới
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdateUser}
          >
            Lưu thay đổi
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
