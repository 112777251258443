import React from "react";
import { PERMISSION_TYPE } from "../../constants/permission";
import { StyledCustomPermissionTypeTag } from "./index.style";

const PermissionTypeTag = ({ type }) => {
  let color = "";
  let background = "";

  switch (type) {
    case PERMISSION_TYPE.API:
      color = "#28C76F";
      background = "#CEF9BF";
      break;
    case PERMISSION_TYPE.MENU:
      color = "#FC6634";
      background = "#FFE9E2";
      break;
    default:
      break;
  }

  return (
    <StyledCustomPermissionTypeTag color={color} background={background}>
      {type}
    </StyledCustomPermissionTypeTag>
  );
};

export default PermissionTypeTag;
