import api from "./api";

const getUsers = async (params) => {
  const response = await api({
    method: "GET",
    url: "/users",
    params,
  });

  return response;
};

const createUser = async (data) => {
  const response = await api({
    method: "POST",
    url: "/users",
    data,
  });

  return response;
};

const updateUser = async (userId, data) => {
  const response = await api({
    method: "PUT",
    url: `/users/${userId}`,
    data,
  });

  return response;
};

const getUser = async (userId) => {
  const response = await api({
    method: "GET",
    url: `/users/${userId}`,
  });

  return response;
};

export { getUsers, createUser, updateUser, getUser };
