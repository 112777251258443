import * as auth from "./auth";
import * as address from "./address";
import * as groupPermission from "./groupPermission";
import * as permission from "./permission";
import * as role from "./role";
import * as user from "./user";
import * as service from "./service";
import * as customer from "./customer";
import * as order from "./order";
import * as orderItem from "./orderItem";
import * as history from "./history";
import * as statistic from "./statistic";
import * as contribution from "./contribution";

const allApi = {
  auth,
  address,
  groupPermission,
  permission,
  role,
  user,
  service,
  customer,
  order,
  orderItem,
  history,
  statistic,
  contribution,
};

export default allApi;
