import { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import PasswordIcon from "@mui/icons-material/Password";

import apis from "../../apis";
import { handleCallApiError } from "../../errors";
import { BORDER_RADIUS, TOOLTIP_BOX_SHADOW } from "../../styles/config";
import { formatMoney } from "../../utils/money";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { LoadingButton } from "@mui/lab";
import { COLOR } from "../../styles/color";
import { toast } from "react-toastify";

const INIT_LOADING = {
  workTime: false,
  contribution: false,
  changePassword: false,
};
const INIT_CHANGE_PASSWORD = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};
const INIT_CHANGE_PASSWORD_ERROR = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

const Profile = () => {
  const currentUser = useSelector((state) => state.user);

  const [loading, setLoading] = useState(INIT_LOADING);
  const [workTime, setWorkTime] = useState(0);
  const [contribution, setContribution] = useState(0);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [changePassword, setChangePassword] = useState(INIT_CHANGE_PASSWORD);
  const [changePasswordError, setChangePasswordError] = useState(
    INIT_CHANGE_PASSWORD_ERROR
  );

  const fetchWorkTime = async () => {
    setLoading((prev) => ({ ...prev, workTime: true }));
    try {
      const res = await apis.statistic.getUserWorkTime(currentUser.id, {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      });
      setWorkTime(res.workTime);
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading((prev) => ({ ...prev, workTime: false }));
  };

  const fetchContributionByDay = async () => {
    setLoading((prev) => ({ ...prev, contribution: true }));
    try {
      const res = await apis.statistic.getUserRevenue(currentUser.id, {
        type: "day",
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
      const revenue = res.revenue || {};

      setContribution(
        Object.values(revenue).reduce((acc, item) => acc + item, 0)
      );
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading((prev) => ({ ...prev, contribution: false }));
  };

  const handleCloseDialog = () => {
    setChangePasswordDialog(false);
    setChangePassword(INIT_CHANGE_PASSWORD);
    setChangePasswordError(INIT_CHANGE_PASSWORD_ERROR);
  };

  const handleChangeChangePassword = (event) => {
    const value = event.target.value;
    const field = event.target.name;

    setChangePassword((prev) => ({ ...prev, [field]: value }));
    setChangePasswordError((prev) => ({ ...prev, [field]: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(changePassword[field])) {
      setChangePasswordError((prev) => ({
        ...prev,
        [field]: "Trường này không được để trống",
      }));
      return false;
    }
    return true;
  };

  const validateConfirmPassword = () => {
    if (changePassword.newPassword !== changePassword.confirmNewPassword) {
      setChangePasswordError((prev) => ({
        ...prev,
        confirmNewPassword: "Xác nhận mật khẩu không hợp lệ",
      }));
      return false;
    }

    return true;
  };

  const handleChangePassword = async () => {
    setLoading((prev) => ({ ...prev, changePassword: true }));
    try {
      await apis.auth.changePassword({
        oldPassword: changePassword.oldPassword,
        newPassword: changePassword.newPassword,
      });

      handleCloseDialog();
      toast.success("Thay đổi mật khẩu thành công");
    } catch (error) {
      handleCallApiError(error);
    }
    setLoading((prev) => ({ ...prev, changePassword: false }));
  };

  useEffect(() => {
    fetchWorkTime();
    fetchContributionByDay();
  }, []);

  return (
    <>
      <Box p={2}>
        <Typography variant="h6" mb={2}>
          Thông tin
        </Typography>
        <Box
          sx={{
            px: 4,
            py: 3,
            mb: 3,
            borderRadius: BORDER_RADIUS,
            boxShadow: TOOLTIP_BOX_SHADOW,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Họ và tên</Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography>{currentUser.name}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Số điện thoại</Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography>{currentUser.phoneNumber}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Email</Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography>{currentUser.email}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Ngày sinh</Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography>{currentUser.dateOfBirth}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>Vị trí</Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Typography>{currentUser.role.name}</Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={3}></Grid>
            <Grid item xs={6} sm={6} md={3}></Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>
                  Tổng doanh thu trong tháng
                </Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              {loading.contribution ? (
                <Skeleton variant="text" sx={{ fontSize: "14px" }} />
              ) : (
                <Typography>{formatMoney(contribution)} VNĐ</Typography>
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography fontWeight={500}>
                  Tổng thời gian tour trong tháng
                </Typography>
                <Typography fontWeight={500}>:</Typography>
              </Stack>
            </Grid>
            <Grid item xs={6} sm={6} md={3}>
              {loading.workTime ? (
                <Skeleton variant="text" sx={{ fontSize: "14px" }} />
              ) : (
                <Typography>{formatMoney(workTime)} phút</Typography>
              )}
            </Grid>
          </Grid>
        </Box>

        <Button
          variant="outlined"
          startIcon={<PasswordIcon />}
          color="secondary"
          onClick={() => setChangePasswordDialog(true)}
        >
          Đổi mật khẩu
        </Button>
      </Box>
      <Dialog
        title="Đổi mật khẩu"
        maxWidth="sm"
        open={changePasswordDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Box mb={2}>
            <Typography fontWeight={500} mb={1}>
              Mật khẩu cũ <span style={{ color: COLOR.error }}>*</span>:
            </Typography>
            <TextField
              value={changePassword.oldPassword}
              name="oldPassword"
              size="small"
              type="password"
              fullWidth
              onChange={handleChangeChangePassword}
              onBlur={() => validateRequired("oldPassword")}
              error={!!changePasswordError.oldPassword}
              helperText={changePasswordError.oldPassword}
            />
          </Box>
          <Box mb={2}>
            <Typography fontWeight={500} mb={1}>
              Mật khẩu mới <span style={{ color: COLOR.error }}>*</span>:
            </Typography>
            <TextField
              value={changePassword.newPassword}
              name="newPassword"
              size="small"
              type="password"
              fullWidth
              onChange={handleChangeChangePassword}
              onBlur={() => validateRequired("newPassword")}
              error={!!changePasswordError.newPassword}
              helperText={changePasswordError.newPassword}
            />
          </Box>
          <Box mb={2}>
            <Typography fontWeight={500} mb={1}>
              Xác nhận mật khẩu mới{" "}
              <span style={{ color: COLOR.error }}>*</span>:
            </Typography>
            <TextField
              value={changePassword.confirmNewPassword}
              name="confirmNewPassword"
              size="small"
              type="password"
              fullWidth
              onChange={handleChangeChangePassword}
              onBlur={() =>
                validateRequired("confirmNewPassword") &&
                validateConfirmPassword()
              }
              error={!!changePasswordError.confirmNewPassword}
              helperText={changePasswordError.confirmNewPassword}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            Hủy
          </Button>

          <LoadingButton
            variant="contained"
            color="success"
            loading={loading.changePassword}
            onClick={handleChangePassword}
          >
            Lưu thay đổi
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Profile;
