const INIT_USER_ERROR = {
  name: "",
  email: "",
  dateOfBirth: "",
  phoneNumber: "",
  password: "",
  roleId: "",
};

const INIT_USER = {
  name: "",
  email: "",
  dateOfBirth: null,
  phoneNumber: "",
  password: "",
  roleId: "",
};

export { INIT_USER_ERROR, INIT_USER };
